import React from "react"

interface Props {
  style?: any
}

const Stripes = ({ style }: Props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1391.46 1024"
    xmlSpace="preserve"
    style={style}
  >
    <g
      id="Group_11_"
      transform="translate(5907.250000, 2047.707818) rotate(30.000000) translate(-5907.250000, -2047.707818) translate(5117.250000, -772.792182)"
    >
      <rect
        id="Rectangle-Copy_54_"
        x="-5234.58"
        y="4097.51"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -989.3343 9110.7656)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#05ABF8" }}
        width="2155.76"
        height="120.42"
      />

      <rect
        id="Rectangle-Copy-2_34_"
        x="-5391.97"
        y="4062.79"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -1214.4575 9222.2969)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#05ABF8" }}
        width="2155.76"
        height="120.42"
      />

      <rect
        id="Rectangle-Copy-4_34_"
        x="-5549.25"
        y="4028.43"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -1439.0846 9334.1582)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#05ABF8" }}
        width="2155.76"
        height="120.42"
      />

      <rect
        id="Rectangle-Copy-3_54_"
        x="-5706.27"
        y="3993.6"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -1663.8779 9445.1934)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#05ABF8" }}
        width="2155.76"
        height="120.42"
      />
    </g>
    <g
      id="Group-2-Copy-3_6_"
      transform="translate(7021.500000, 2161.500000) rotate(30.000000) translate(-7021.500000, -2161.500000) translate(6880.000000, -313.000000)"
    >
      <rect
        id="Rectangle_34_"
        x="-6639.81"
        y="4117.8"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2893.9778 10566.6367)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy_45_"
        x="-6629.93"
        y="4119.88"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2879.9387 10559.5117)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-3_45_"
        x="-6619.93"
        y="4122.33"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2865.4036 10552.7158)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-2_26_"
        x="-6609.68"
        y="4124.29"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2851.0347 10545.0938)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-7_17_"
        x="-6599.31"
        y="4126.62"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2836.1694 10537.8018)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-6_17_"
        x="-6589.43"
        y="4128.7"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2822.1304 10530.6758)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-5_17_"
        x="-6579.43"
        y="4131.15"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2807.5952 10523.8799)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-4_26_"
        x="-6569.55"
        y="4133.23"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2793.5562 10516.7549)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle_33_"
        x="-6559.18"
        y="4135.56"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2778.6912 10509.4629)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy_41_"
        x="-6548.82"
        y="4137.88"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2763.8259 10502.1709)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-3_41_"
        x="-6538.94"
        y="4139.96"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2749.7869 10495.0449)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />
    </g>
    <g
      id="Group-2_6_"
      transform="translate(4775.968566, 1964.000000) rotate(30.000000) translate(-4775.968566, -1964.000000) translate(4634.468566, -510.500000)"
    >
      <rect
        id="Rectangle_32_"
        x="-5437.5"
        y="3353.54"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2179.7151 8463.9297)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy_40_"
        x="-5427.62"
        y="3355.62"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2165.676 8456.8037)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-3_40_"
        x="-5417.62"
        y="3358.07"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2151.1406 8450.0088)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-2_25_"
        x="-5407.37"
        y="3360.03"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2136.7717 8442.3867)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-7_16_"
        x="-5397.01"
        y="3362.36"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2121.9067 8435.0947)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-6_16_"
        x="-5387.13"
        y="3364.44"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2107.8677 8427.9688)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-5_16_"
        x="-5377.13"
        y="3366.89"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2093.3323 8421.1729)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-4_25_"
        x="-5367.24"
        y="3368.97"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2079.2935 8414.0479)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle_31_"
        x="-5356.88"
        y="3371.3"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2064.4282 8406.7559)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy_39_"
        x="-5346.52"
        y="3373.63"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2049.563 8399.4639)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />

      <rect
        id="Rectangle-Copy-3_39_"
        x="-5336.63"
        y="3375.71"
        transform="matrix(-0.2149 0.9766 -0.9766 -0.2149 -2035.524 8392.3379)"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FE5000" }}
        width="1891.22"
        height="4.59"
      />
    </g>
    <rect
      x="-835.54"
      y="-93.45"
      style={{ fill: "none" }}
      width="2121.02"
      height="1193.08"
    />
  </svg>
)

export default Stripes
