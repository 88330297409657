import styled from "styled-components"
import { Sizes } from "@/utils/vars"

export const Leaf = styled.div`
  margin-right: auto;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 50px;
  height: 50px;
  z-index: 6;

  @media only screen and (min-width: ${Sizes.sm}px) {
    top: 50px;
    left: 50px;
  }
`
