import i18n from "@/utils/i18n"
import { Sizes } from "@/utils/vars"
import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 15px;
  border: 4px solid #ff4d00;
  border-radius: 3px;
  position: relative;

  @media only screen and (min-width: ${Sizes.sm}px) {
    padding: 30px;
  }
`
const Header = styled.div`
  position: relative;
`

const Title = styled.h3`
  font-family: "Noto Sans JP";
  font-size: 24px;
  font-weight: 900;
  color: #1c1c1f;
  margin-bottom: 0.7rem;
`

const DatePeriod = styled.h4`
  font-family: "Noto Sans JP";
  font-size: 20px;
  font-weight: 500;
  color: #ff4d00;
  margin-bottom: 0.7rem;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${Sizes.lg}px) {
    flex-direction: row;
  }
`

const Content = styled.div`
  width: 100%;
`

const Body = styled.div`
  text-align: left;
  font-family: "Noto Sans JP";
  font-size: 14px;
  color: #1c1c1f;
  width: 100%;
  line-height: 1.25;
  letter-spacing: 0.6px;
`

const Bookmark = styled.div`
  position: absolute;
  left: -155px;
  height: 60px;
  display: none;
  z-index: 3;

  @media only screen and (min-width: ${Sizes.lg}px) {
    display: flex;
  }
`

const Skew = styled.div`
  background-color: #ff4d00;
  transform: skew(-45deg);
`

const SkewTriangle = styled.div`
  background-color: #ff4d00;
  transform: skew(-45deg);

  clip-path: polygon(0% 100%, 100% 100%, -50% 0px);

  @media only screen and (min-width: ${Sizes.md}px) {
    clip-path: polygon(0% 100%, 100% 100%, -26px 0px);
  }
`

const SkewTriangle2 = styled.div`
  background-color: #ff4d00;
  transform: skew(-45deg);

  clip-path: polygon(0% 100%, 100% 100%, -50% 0px);

  @media only screen and (min-width: ${Sizes.md}px) {
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 95% 50%);
  }
`

const BookmarkMask = styled.div`
  background-color: white;
  height: 90px;
  position: absolute;
  left: -6px;
  width: 8px;
  z-index: 2;
  transform: translateY(-15%);
  display: none;

  @media only screen and (min-width: ${Sizes.lg}px) {
    display: block;
  }
`

const ScheduleTitle = styled.h4`
  font-size: 24px;
  color: #1c1c1f;
  font-weight: 900;
  text-align: left;
  text-transform: uppercase;
  margin: 15px 0 0 0;
`

const Schedule = styled.div`
  color: #000;
  font-family: "Noto Sans JP";
`

const Dates = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media only screen and (min-width: ${Sizes.md}px) {
    flex-direction: row;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 4px;
  background-color: #ff4d00;
  border-radius: 4px;
`

const Year = styled.div`
  font-size: 36px;
  background-color: #fff;
  color: #ff4d00;
  font-family: "Noto Sans JP";
  font-weight: 900;
  position: absolute;
  right: 32px;
  top: -18px;
  padding: 0 8px;
`

const ComingSoonContainer = styled.div`
  width: 100%;
`

const ComingSoon = styled.div`
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 900;
  line-height: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #ff4d00;
`

const ImageWrapper = styled.div`
padding: 0 0 0 30px;
max-width: 280px;
  min-width: 217px;
  float: right;

  @media only screen and (min-width: ${Sizes.lg}px) {
  }
`

interface Props {
  title: string
  date: string
  year: string
  children?: any
  schedule: any
  headerImage: any
}

const Event = ({ title, date, year, schedule, children, headerImage }: Props) => {
  return (
    <Wrapper>
      <Year>{year}</Year>
      <ContentWrapper>
      <Bookmark>
              <SkewTriangle2
                style={{
                  width: "32px",
                  height: "100%",
                  marginLeft: "32px",
                }}
              />
              <div style={{ width: "28px", height: "100%" }}></div>
              <Skew style={{ width: "48px", height: "100%" }} />
              <div style={{ width: "28px", height: "100%" }}></div>
              <SkewTriangle
                style={{
                  width: "32px",
                  height: "100%",
                }}
              />
            </Bookmark>
            <BookmarkMask />
        <Content>
          <Header>
            
            <a href="https://social-innovation-week-shibuya.jp/" style={{textDecoration: 'none'}}><Title>{title}</Title></a>
            <DatePeriod>{date}</DatePeriod>
          </Header>
          <Body>
            <div>
            <ImageWrapper>
              <img src={headerImage} style={{marginBottom: 0}}/>
            </ImageWrapper>{children}</div>
          </Body>
        </Content>
      </ContentWrapper>
      
      <Divider style={{margin: '30px 0'}}/>
      <Schedule>
        {/* <ScheduleTitle>{i18n("home.events.event.schedule")}</ScheduleTitle> */}
        <Dates>
          {schedule.length === 0 && (
            <ComingSoonContainer>
              <ComingSoon>{i18n("home.events.event.comingSoon")}</ComingSoon>
            </ComingSoonContainer>
          )}
          {schedule.length > 0 &&
            schedule.map((date: any, i: number) => (
              <div style={{maxWidth: '100%'}} key={i}>
                { i > 0 && <Divider style={{margin: '30px 0'}}/>}
                {date}
                </div>
            ))}
        </Dates>
      </Schedule>
    </Wrapper>
  )
}

export default Event
