import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faLine, faTwitch, faYoutube } from "@fortawesome/free-brands-svg-icons"
import IconLink from "@/components/Common/Buttons/IconLink"
import i18n from "@/utils/i18n"
import { Container } from "./styles"

const SocialMedia = () => {
  const url = typeof window !== "undefined" ? window.location.href : ""

  return (
    <Container>
      <IconLink
        to={String(process.env.GATSBY_SOCIAL_MEDIA_TWITTER_URL)}
        title={i18n("home.landing.socialLinks.twitter")}
      >
        <FontAwesomeIcon icon={faTwitter} size="2x" />
      </IconLink>
      <IconLink
        to={String(process.env.GATSBY_SOCIAL_MEDIA_YOUTUBE_URL)}
        title={i18n("home.landing.socialLinks.youtube")}
      >
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </IconLink>
      <IconLink
        to={String(process.env.GATSBY_SOCIAL_MEDIA_TWITCH_URL)}
        title={i18n("home.landing.socialLinks.twitch")}
      >
        <FontAwesomeIcon icon={faTwitch} size="2x" />
      </IconLink>
      <IconLink
        to={`http://line.me/R/msg/text/?${encodeURIComponent(
          i18n("home.landing.socialLinks.lineShare")
        )}%0A${encodeURIComponent(url)}`}
        title={i18n("home.landing.socialLinks.line")}
      >
        <FontAwesomeIcon icon={faLine} size="2x" />
      </IconLink>
    </Container>
  )
}

export default SocialMedia
