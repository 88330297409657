import React from 'react'
import styled from 'styled-components'
import Stripes from "@/components/Common/Backgrounds/Stripes"

const Wrapper = styled.div`
    min-width: 173px;
    display: flex;
    padding: 15px 0 0 0;
    max-width: 170px;
    flex-direction: column;
`

const ImageContainer = styled.div`
    width: 160px;
    height: 160px;
    position: relative;
    overflow: hidden;
    background-color: #4b4b4b;
    min-width: 160px;
`

const ImageContainerTopLines = styled.div`
    position: absolute;
    width: 50%;
    height: 50%;
    top: -12%;
    left: -25%;
`

const ImageContainerBottomLines = styled.div`
    position: absolute;
    width: 50%;
    height: 50%;
    bottom: -25%;
    right: -25%;
`

const Title = styled.div`
    font-family: "Noto Sans JP";
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: #1c1c1f;
`

interface Props {
    image: any
    name: string
}


const Player = ({image, name}: Props) => {
    return (
        <Wrapper>
            <ImageContainer>
                <ImageContainerTopLines>
                    <Stripes style={{ transform: "rotateZ(180deg)" }}  />
                </ImageContainerTopLines>
                <img src={image} style={{marginBottom: 0, display: 'block'}}/>
                <ImageContainerBottomLines>
                    <Stripes/>
                </ImageContainerBottomLines>
            </ImageContainer>
            <Title>{name}</Title>
        </Wrapper>
    )
}

export default Player
