import React from "react"

interface Props {
  style?: any
}

const Icon = ({ style }: Props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1024 1024"
    xmlSpace="preserve"
    style={style}
  >
    <g>
      <polygon
        style={{ fill: "#05ABF8" }}
        points="497.81,312.88 497.81,438.45 353,458.8 353,333.23 	"
      />
      <polygon
        style={{ fill: "#05ABF8" }}
        points="497.81,583.26 497.81,718.41 353,738.76 353,603.61 	"
      />
      <g>
        <path
          style={{ fill: "#F2F2F2" }}
          d="M671.57,288.52L353,333.3V213.49c0-13.81,11.19-26.57,25-28.51l293.58-41.26V288.52z"
        />
      </g>
      <g>
        <polygon
          style={{ fill: "#F2F2F2" }}
          points="671.57,558.84 353,603.61 353,458.8 671.57,414.03 		"
        />
      </g>
      <g>
        <path
          style={{ fill: "#F2F2F2" }}
          d="M671.57,838.8L378,880.06c-13.81,1.94-25-7.68-25-21.49V738.76l318.58-44.77V838.8z"
        />
      </g>
    </g>
  </svg>
)

export default Icon
