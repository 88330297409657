import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { Link, ButtonBase, ScrollContainer } from "./styles"

const ScrollUpButton = () => (
  <ScrollContainer>
    <Link href="#pageTop">
      <ButtonBase>
        <FontAwesomeIcon icon={faChevronUp} size="lg" />
      </ButtonBase>
    </Link>
  </ScrollContainer>
)

export default ScrollUpButton
