import React from "react"

interface Props {
  style?: any
}

const Logo = ({ style }: Props) => (
    <svg viewBox="0 0 1023 1080" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve"
    style={{fillRule: 'evenodd', clipRule:'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
    <g transform="matrix(0.866025,0.5,-0.5,0.866025,8136.21,-52.2299)">
        <g>
            <g>
                <g>
                    <g id="Group-2-Copy-3_1_">
                        <clipPath id="_clip1">
                            <path d="M1424.97,716.64C1429.53,723.91 1434.6,730.94 1440.17,737.64C1452.63,752.73 1467.47,766.25 1484.32,777.71C1492.97,783.6 1502.12,788.97 1511.78,793.69L1525.28,800.31L1573.08,823.76L1614.03,843.82L1635.1,854.15L1658.17,842.85L1699.13,822.79L1746.93,799.34L1758.47,793.69C1768.81,788.6 1778.66,782.79 1787.89,776.34C1804.81,764.6 1819.69,750.71 1832.04,735.26C1851.07,711.53 1864.19,684.21 1869.73,655.31C1869.81,654.9 1869.87,654.49 1869.95,654.08L1424.97,716.64Z"/>
                        </clipPath>
                        <g clipPath="url(#_clip1)">
                            <g id="Rectangle_4_">
                                <path d="M-6320.47,3674.73L-6321.78,3678.76L-4653.28,4197.39L-4651.98,4193.35L-6320.47,3674.73Z" style={{fill: 'rgb(254,80,0)'}}/>
                            </g>
                            <g id="Rectangle-Copy_42_">
                                <path d="M-6323.43,3683.59L-6324.73,3687.63L-4656.24,4206.25L-4654.93,4202.22L-6323.43,3683.59Z" style={{fill: 'rgb(254,80,0)'}}/>
                            </g>
                            <g id="Rectangle-Copy-3_42_">
                                <path d="M-6326.14,3692.71L-6327.45,3696.75L-4658.96,4215.37L-4657.65,4211.34L-6326.14,3692.71Z" style={{fill: 'rgb(254,80,0)'}}/>
                            </g>
                            <g id="Rectangle-Copy-2_26_">
                                <path d="M-6329.36,3701.81L-6330.67,3705.85L-4662.17,4224.48L-4660.87,4220.44L-6329.36,3701.81Z" style={{fill: 'rgb(254,80,0)'}}/>
                            </g>
                            <g id="Rectangle-Copy-7_2_">
                                <path d="M-6332.34,3711.17L-6333.65,3715.21L-4665.15,4233.84L-4663.85,4229.8L-6332.34,3711.17Z" style={{fill: 'rgb(254,80,0)'}}/>
                            </g>
                            <g id="Rectangle-Copy-6_2_">
                                <path d="M-6335.29,3720.03L-6336.6,3724.07L-4668.11,4242.7L-4666.8,4238.66L-6335.29,3720.03Z" style={{fill: 'rgb(254,80,0)'}}/>
                            </g>
                            <g id="Rectangle-Copy-5_2_">
                                <path d="M-6338.01,3729.15L-6339.32,3733.19L-4670.82,4251.82L-4669.52,4247.78L-6338.01,3729.15Z" style={{fill: 'rgb(254,80,0)'}}/>
                            </g>
                            <g id="Rectangle-Copy-4_26_">
                                <path d="M-6340.97,3738.02L-6342.27,3742.05L-4673.78,4260.68L-4672.47,4256.64L-6340.97,3738.02Z" style={{fill: 'rgb(254,80,0)'}}/>
                            </g>
                            <g id="Rectangle_3_">
                                <path d="M-6343.95,3747.38L-6345.25,3751.41L-4676.76,4270.04L-4675.45,4266L-6343.95,3747.38Z" style={{fill: 'rgb(254,80,0)'}}/>
                            </g>
                            <g id="Rectangle-Copy_5_">
                                <path d="M-6346.92,3756.74L-6348.23,3760.77L-4679.74,4279.4L-4678.43,4275.36L-6346.92,3756.74Z" style={{fill: 'rgb(254,80,0)'}}/>
                            </g>
                            <g id="Rectangle-Copy-3_5_">
                                <path d="M-6349.88,3765.6L-6351.18,3769.64L-4682.69,4288.26L-4681.39,4284.23L-6349.88,3765.6Z" style={{fill: 'rgb(254,80,0)'}}/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
    <g>
        <g>
            <g>
                <path d="M747.62,223.43L747.62,622.14C747.62,633.77 746.54,645.51 744.35,656.98C738.81,685.99 725.7,713.41 706.66,737.23C694.32,752.75 679.43,766.68 662.51,778.47C653.28,784.95 643.44,790.79 633.09,795.89L621.55,801.56L573.75,825.1L532.79,845.23L509.72,856.57L488.65,846.2L447.7,826.07L399.9,802.53L386.4,795.89C376.75,791.15 367.59,785.76 358.94,779.85C342.1,768.34 327.25,754.77 314.79,739.62C294.68,715.31 280.87,686.96 275.14,656.98C274.64,654.39 274.18,651.75 273.83,649.12C272.48,640.17 271.83,631.13 271.83,622.14L271.83,223.43L747.62,223.43Z" style={{fill: 'none', fillRule: 'nonzero'}}/>
            </g>
        </g>
        <g>
            <g>
                <path d="M747.62,223.43L747.62,620.62C747.62,632.2 746.54,643.91 744.35,655.33C738.81,684.23 725.7,711.55 706.66,735.28C694.32,750.74 679.43,764.62 662.51,776.36C653.28,782.82 643.44,788.63 633.09,793.71L621.55,799.36L573.75,822.81L532.79,842.87L509.72,854.17L488.65,843.84L447.7,823.78L399.9,800.33L386.4,793.71C376.75,788.99 367.59,783.62 358.94,777.73C342.1,766.27 327.25,752.75 314.79,737.66C294.68,713.45 280.87,685.2 275.14,655.33C274.64,652.75 274.18,650.12 273.83,647.5C272.48,638.58 271.83,629.58 271.83,620.62L271.83,223.43L747.62,223.43Z" style={{fill: 'none', fillRule: 'nonzero'}}/>
            </g>
        </g>
        <g>
            <g>
                <path d="M802.98,556.72L219.28,638.77L219.28,375.61L802.98,293.58L802.98,556.72Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path d="M353.69,410.22L353.69,425.31L336.28,427.76L336.28,412.67L353.69,410.22Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M353.69,442.72L353.69,458.96L336.28,461.41L336.28,445.16L353.69,442.72Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M353.69,516.39L353.69,531.47L336.28,533.92L336.28,518.83L353.69,516.39Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M353.69,548.88L353.69,565.13L336.28,567.57L336.28,551.33L353.69,548.88Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M437.11,431.67L437.11,450.7C437.11,459.52 433.17,463.32 428.06,465.89L400.91,469.71C395.8,468.57 391.86,465.88 391.86,457.06L391.86,440.58L410.42,437.97L410.42,452.13L411.58,453.13L417.38,452.31L418.54,450.99L418.54,438.23L417.5,436.75L398.36,430.85C392.67,429.1 391.86,425.27 391.86,419L391.86,401.36C391.86,392.54 395.8,388.74 400.91,386.16L428.06,382.34C433.17,383.48 437.11,386.17 437.11,394.99L437.11,410.54L418.55,413.15L418.55,399.92L417.39,398.92L411.59,399.74L410.43,401.06L410.43,413.24L411.94,414.88L430.62,419.8C436.3,421.34 437.11,425.41 437.11,431.67Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M473.08,430.56L473.08,459.57L454.52,462.18L454.52,378.64L490.72,373.55C495.83,374.69 499.77,377.38 499.77,386.2L499.77,412.89C499.77,421.71 495.83,425.51 490.72,428.08L473.08,430.56ZM473.08,391.11L473.08,415.48L480.04,414.5L481.2,413.18L481.2,391.14L480.04,390.14L473.08,391.11Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M561.83,433.34C561.83,442.16 557.89,445.96 552.78,448.53L525.63,452.35C520.52,451.21 516.58,448.52 516.58,439.7L516.58,384C516.58,375.18 520.52,371.38 525.63,368.8L552.78,364.98C557.89,366.12 561.83,368.81 561.83,377.63L561.83,433.34ZM543.27,382.57L542.11,381.57L536.31,382.39L535.15,383.71L535.15,434.76L536.31,435.76L542.11,434.94L543.27,433.62L543.27,382.57Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M578.18,444.81L578.18,361.27L614.38,356.18C619.49,357.32 623.43,360.01 623.43,368.83L623.43,386.58C623.43,394.59 620.18,398.52 615.66,401.13L625.64,426.41L625.64,438.13L609.4,440.41L598.73,409.42L596.76,409.7L596.76,442.19L578.18,444.81ZM596.75,373.75L596.75,394.63L603.71,393.65L604.87,392.33L604.87,373.77L603.71,372.77L596.75,373.75Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M672.63,365.4L672.63,431.53L654.07,434.14L654.07,368L640.73,369.88L640.73,352.48L685.98,346.12L685.98,363.52L672.63,365.4Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M748.52,387.91L748.52,406.94C748.52,415.76 744.57,419.56 739.47,422.13L712.32,425.95C707.22,424.81 703.27,422.12 703.27,413.3L703.27,396.82L721.83,394.21L721.83,408.37L722.99,409.37L728.79,408.55L729.95,407.23L729.95,394.47L728.91,392.99L709.77,387.09C704.08,385.34 703.27,381.51 703.27,375.24L703.27,357.6C703.27,348.78 707.22,344.98 712.32,342.4L739.47,338.58C744.57,339.72 748.52,342.41 748.52,351.23L748.52,366.78L729.96,369.39L729.96,356.16L728.8,355.16L723,355.98L721.84,357.3L721.84,369.48L723.35,371.12L742.03,376.04C747.71,377.58 748.52,381.64 748.52,387.91Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M273.74,593.76L273.74,510.22L309.94,505.13C315.05,506.27 318.99,508.96 318.99,517.78L318.99,530.19C318.99,536.92 316.67,542 309.82,545.4C316.67,546.87 318.99,551.31 318.99,558.03L318.99,573.46C318.99,582.28 315.05,586.08 309.94,588.65L273.74,593.76ZM292.3,542.42L299.26,541.44L300.42,540.12L300.42,521.56L299.26,520.56L292.3,521.54L292.3,542.42ZM300.43,574.92L300.43,556.36L299.27,555.36L292.31,556.34L292.31,577.22L299.27,576.24L300.43,574.92Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M405.31,575.25L405.31,542.41L391.39,507.7L391.39,493.66L408.79,491.21L414.01,519.13L415.17,518.97L420.39,489.58L437.79,487.13L437.79,501.17L423.87,539.79L423.87,572.63L405.31,575.25Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M499.87,548.04C499.87,556.86 495.93,560.66 490.82,563.23L463.67,567.05C458.56,565.91 454.62,563.22 454.62,554.4L454.62,498.7C454.62,489.88 458.56,486.08 463.67,483.5L490.82,479.68C495.93,480.82 499.87,483.51 499.87,492.33L499.87,548.04ZM481.31,497.27L480.15,496.27L474.35,497.09L473.19,498.41L473.19,549.46L474.35,550.46L480.15,549.64L481.31,548.32L481.31,497.27Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M545.59,471.98L561.83,469.7L561.83,553.24L545.59,555.52L533.99,520.72L532.83,520.88L532.83,557.31L516.59,559.59L516.59,476.05L532.83,473.77L544.43,508.57L545.59,508.41L545.59,471.98Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M623.32,530.77C623.32,539.59 619.38,543.39 614.27,545.96L578.07,551.05L578.07,467.51L614.27,462.42C619.38,463.56 623.32,466.25 623.32,475.07L623.32,530.77ZM596.64,479.99L596.64,533.36L603.6,532.38L604.76,531.06L604.76,480L603.6,479L596.64,479.99Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M374.57,407.29L336.28,412.67L336.28,398.27C336.28,396.61 337.63,395.08 339.28,394.84L374.56,389.88L374.56,407.29L374.57,407.29Z" style={{fill: 'rgb(242,242,242)' ,fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M374.57,439.78L336.28,445.16L336.28,427.76L374.57,422.38L374.57,439.78Z" style={{fill: 'rgb(242,242,242)' ,fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M374.57,473.43L339.29,478.39C337.63,478.62 336.29,477.47 336.29,475.81L336.29,461.41L374.58,456.03L374.58,473.43L374.57,473.43Z" style={{fill: 'rgb(242,242,242)' ,fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M374.57,513.45L336.28,518.83L336.28,504.43C336.28,502.77 337.63,501.24 339.28,501L374.56,496.04L374.56,513.45L374.57,513.45Z" style={{fill: 'rgb(242,242,242)' ,fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M374.57,545.94L336.28,551.32L336.28,533.92L374.57,528.53L374.57,545.94Z" style={{fill: 'rgb(242,242,242)' ,fillRule: 'nonzero'}}/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M374.57,579.59L339.29,584.55C337.63,584.78 336.29,583.63 336.29,581.97L336.29,567.57L374.58,562.19L374.58,579.59L374.57,579.59Z" style={{fill:'rgb(242,242,242)', fillRule:'nonzero'}}/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
        <g>
            <g>
                <g>
                    <path d="M320.76,670.2L320.76,681C320.76,686 318.52,688.16 315.62,689.62L300.21,691.78C297.31,691.13 295.07,689.61 295.07,684.6L295.07,675.25L305.6,673.77L305.6,681.8L306.26,682.37L309.55,681.91L310.21,681.16L310.21,673.92L309.62,673.08L298.76,669.73C295.53,668.74 295.07,666.56 295.07,663.01L295.07,653C295.07,648 297.31,645.84 300.21,644.38L315.62,642.21C318.52,642.86 320.76,644.39 320.76,649.39L320.76,658.21L310.23,659.69L310.23,652.19L309.57,651.62L306.28,652.08L305.62,652.83L305.62,659.74L306.48,660.67L317.08,663.46C320.3,664.34 320.76,666.65 320.76,670.2Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                    <path d="M342.16,667.46L337.55,668.11L337.55,686.54L327.02,688.02L327.02,640.62L337.55,639.14L337.55,658.23L342.16,657.58L342.16,638.49L352.69,637.01L352.69,684.41L342.16,685.89L342.16,667.46Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                    <path d="M359.27,683.49L359.27,673.62L361.25,673.34L361.25,645.69L359.27,645.97L359.27,636.1L373.75,634.06L373.75,643.93L371.77,644.21L371.77,671.86L373.75,671.58L373.75,681.45L359.27,683.49Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                    <path d="M380.34,680.53L380.34,633.13L400.88,630.24C403.78,630.89 406.02,632.42 406.02,637.42L406.02,644.46C406.02,648.28 404.7,651.16 400.82,653.09C404.7,653.93 406.02,656.44 406.02,660.26L406.02,669.02C406.02,674.02 403.78,676.18 400.88,677.64L380.34,680.53ZM390.87,651.4L394.82,650.84L395.48,650.09L395.48,639.56L394.82,638.99L390.87,639.54L390.87,651.4ZM395.48,669.84L395.48,659.31L394.82,658.74L390.87,659.3L390.87,671.15L394.82,670.59L395.48,669.84Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                    <path d="M423.13,665.3L423.79,665.87L426.42,665.5L427.08,664.75L427.08,626.57L437.61,625.09L437.61,664.59C437.61,669.59 435.37,671.75 432.47,673.21L417.72,675.28C414.82,674.63 412.58,673.1 412.58,668.1L412.58,628.6L423.11,627.12L423.11,665.3L423.13,665.3Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                    <path d="M451.44,670.54L451.44,651.9L443.54,632.21L443.54,624.24L453.42,622.85L456.38,638.7L457.04,638.61L460,621.93L469.88,620.54L469.88,628.51L461.98,650.42L461.98,669.05L451.44,670.54Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                    <path d="M488.18,656.16L483.18,656.86L482.39,666.19L472.51,667.58L472.51,659.61L478.5,619.33L492.85,617.31L498.84,655.9L498.84,663.87L488.96,665.26L488.18,656.16ZM484.03,646.86L487.32,646.4L486,631.64L485.34,631.73L484.03,646.86Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                    <path d="M543.61,649.68C543.61,654.68 541.37,656.84 538.47,658.3L523.06,660.46C520.16,659.81 517.92,658.29 517.92,653.28L517.92,621.68C517.92,616.68 520.16,614.52 523.06,613.06L538.47,610.89C541.37,611.54 543.61,613.07 543.61,618.07L543.61,629.52L533.08,631L533.08,620.86L532.42,620.29L529.13,620.75L528.47,621.5L528.47,650.47L529.13,651.04L532.42,650.58L533.08,649.83L533.08,639.82L543.61,638.34L543.61,649.68Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                    <path d="M560.73,645.96L561.39,646.53L564.02,646.16L564.68,645.41L564.68,607.23L575.21,605.75L575.21,645.25C575.21,650.25 572.97,652.41 570.07,653.87L555.32,655.94C552.42,655.29 550.18,653.76 550.18,648.76L550.18,609.26L560.71,607.78L560.71,645.96L560.73,645.96Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                    <path d="M592.33,634.28L592.33,650.74L581.8,652.22L581.8,604.82L602.34,601.93C605.24,602.58 607.48,604.1 607.48,609.11L607.48,624.25C607.48,629.25 605.24,631.41 602.34,632.87L592.33,634.28ZM592.33,611.89L592.33,625.72L596.28,625.16L596.94,624.41L596.94,611.9L596.28,611.33L592.33,611.89Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                    <path d="M626.56,645.92L626.56,630.12C626.56,626.56 627.28,624.09 630.25,622.36L641.71,615.61L641.71,605.6L641.05,605.03L637.76,605.49L637.1,606.24L637.1,613.09L626.56,614.57L626.56,606.41C626.56,601.41 628.8,599.25 631.7,597.79L647.11,595.62C650.01,596.27 652.25,597.8 652.25,602.8L652.25,614.78C652.25,618.34 651.79,620.64 648.56,622.54L637.1,629.29L637.1,634.56L652.24,632.43L652.24,642.31L626.56,645.92Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                    <path d="M679.36,638.5L663.95,640.67C661.05,640.02 658.81,638.49 658.81,633.49L658.81,601.89C658.81,596.89 661.05,594.73 663.95,593.27L679.36,591.11C682.26,591.76 684.5,593.28 684.5,598.29L684.5,629.89C684.5,634.88 682.26,637.04 679.36,638.5ZM673.96,601.08L673.3,600.51L670.01,600.97L669.35,601.72L669.35,630.69L670.01,631.26L673.3,630.8L673.96,630.05L673.96,601.08Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                    <path d="M691.08,636.86L691.08,621.06C691.08,617.51 691.8,615.03 694.77,613.3L706.23,606.56L706.23,596.55L705.57,595.98L702.28,596.44L701.62,597.19L701.62,604.04L691.09,605.52L691.09,597.36C691.09,592.36 693.33,590.2 696.23,588.74L711.64,586.57C714.54,587.22 716.78,588.74 716.78,593.75L716.78,605.73C716.78,609.29 716.32,611.59 713.09,613.49L701.63,620.23L701.63,625.5L716.77,623.37L716.77,633.24L691.08,636.86Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                    <path d="M743.88,629.44L728.47,631.6C725.57,630.95 723.33,629.43 723.33,624.42L723.33,592.82C723.33,587.82 725.57,585.66 728.47,584.2L743.88,582.04C746.78,582.69 749.02,584.21 749.02,589.22L749.02,620.82C749.01,625.82 746.77,627.98 743.88,629.44ZM738.48,592.01L737.82,591.44L734.53,591.9L733.87,592.65L733.87,621.62L734.53,622.19L737.82,621.73L738.48,620.98L738.48,592.01Z" style={{fill: 'rgb(41,43,45)', fillRule: 'nonzero'}}/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M379.28,705.44L374.17,706.16L510.53,853.77L514.51,851.82L379.28,705.44Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M368.02,707.02L362.91,707.74L488.6,843.79L488.66,843.82L499.15,848.96L368.02,707.02Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M356.56,708.64L351.45,709.36L464.94,832.2L475.48,837.37L356.56,708.64Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M344.93,710.27L339.82,710.99L440.94,820.45L447.71,823.77L451.5,825.62L344.93,710.27Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M333.1,711.93L327.99,712.65L416.49,808.45L427.05,813.63L333.1,711.93Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M321.85,713.52L316.73,714.24L393.24,797.05L399.91,800.32L403.8,802.22L321.85,713.52Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M310.38,715.13L305.27,715.85L367.59,783.31C371.59,785.78 375.68,788.13 379.88,790.36L310.38,715.13Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M300.91,718.64C305.14,725.2 309.76,731.56 314.81,737.65C324.58,749.49 335.84,760.34 348.36,770L300.91,718.64Z" style={{fill:'rgb(254,80,0)', fillRule:'nonzero'}}/>
                </g>
                <g>
                    <path d="M747.02,223.43L741.02,223.43L747.62,231.01L747.62,224.12L747.02,223.43Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M733.55,223.43L727.55,223.43L747.62,246.49L747.62,239.59L733.55,223.43Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M719.9,223.43L713.9,223.43L747.62,262.18L747.62,255.28L719.9,223.43Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M706,223.43L699.99,223.43L747.62,278.15L747.62,271.25L706,223.43Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M692.78,223.43L686.77,223.43L736.73,280.82L742.08,280.07L692.78,223.43Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M679.31,223.43L673.3,223.43L724.73,282.51L730.08,281.76L679.31,223.43Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M666.09,223.43L660.08,223.43L712.95,284.16L718.3,283.41L666.09,223.43Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M652.18,223.43L646.18,223.43L700.56,285.91L705.91,285.15L652.18,223.43Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M638.28,223.43L632.27,223.43L688.17,287.65L693.52,286.89L638.28,223.43Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                    <path d="M625.06,223.43L619.05,223.43L676.39,289.3L681.74,288.55L625.06,223.43Z" style={{fill: 'rgb(254,80,0)',fillRule:'nonzero'}}/>
                </g>
                <g>
                    <path d="M517.61,686L407.4,701.49L536.39,841.1L573.77,822.8L621.57,799.35L622.09,799.09L517.61,686Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                    <path d="M665.12,665.26L554.91,680.75L650.47,784.19C654.59,781.69 658.62,779.08 662.52,776.35C679.44,764.61 694.32,750.72 706.67,735.27C710.3,730.74 713.71,726.09 716.9,721.31L665.12,665.26Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                    <path d="M732.56,692.86C737.9,680.78 741.88,668.21 744.35,655.32C744.43,654.91 744.49,654.5 744.57,654.09L702.24,660.04L732.56,692.86Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                </g>
                <g>
                    <path d="M271.85,239.57L271.85,346.16L359,333.91L271.85,239.57Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                    <path d="M423.4,223.43L298.88,223.43L396.13,328.69L506.34,313.2L423.4,223.43Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                    <path d="M589.72,223.43L465.2,223.43L543.33,308L653.54,292.51L589.72,223.43Z" style={{fill: 'rgb(5,171,248)', fillRule: 'nonzero'}}/>
                </g>
            </g>
        </g>
    </g>
</svg>

    
)

export default Logo