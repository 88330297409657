import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Sizes } from "@/utils/vars"
import i18n from "@/utils/i18n"
import NavIcon from "../../NavIcon"
import Logo from "./../Icons/Logo"
import SocialMedia from "./SocialMedia"
import ScrollDownButton from "./ScrollDownButton"
import {
  Root,
  BackgroundContainer,
  ContentInner,
  Content,
  TagLine,
  Description,
} from "./styles"

const Landing = () => {
  const data = useStaticQuery(query)

  const [width, setWidth] = useState(320)

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", updateWidth)

    setWidth(window.innerWidth)

    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  const backgroundImage =
    width >= Sizes.lg
      ? data.background.childImageSharp.fluid
      : data.backgroundMobile.childImageSharp.fluid

  return (
    <Root id="pageTop">
      <BackgroundContainer>
        <BackgroundImage
          fluid={backgroundImage}
          alt={i18n("home.landing.splash.altText")}
        >
          <Content>
            <NavIcon />
            <ContentInner>
              <Logo style={{ width: "600px", maxWidth: "100%" }} />
              <SocialMedia />
            </ContentInner>

            <ScrollDownButton />
          </Content>
        </BackgroundImage>
      </BackgroundContainer>
    </Root>
  )
}

export default Landing

export const query = graphql`
  query {
    backgroundMobile: file(
      relativePath: { eq: "images/landing/artMobile.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    background: file(relativePath: { eq: "images/landing/art.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
