import React from 'react'
import Image from 'gatsby-image'
import styled from 'styled-components'
import Stripes from "@/components/Common/Backgrounds/Stripes"
import { Sizes } from '@/utils/vars'

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 15px 0;
    flex-direction: column;
    max-width: 100%;

    @media screen and (min-width: ${Sizes.sm}px) {
        flex-direction: row;
    }

    @media screen and (min-width: ${Sizes.md}px) {
        width: 50%;
    max-width: 434px;
    }
`

const ImageContainer = styled.div`
    width: 50%;
    height: auto;
    max-width: 140px;
    min-width: 140px;
    max-height: 140px;
    position: relative;
    overflow: hidden;
    background-color: #4b4b4b;


    @media screen and (min-width: ${Sizes.sm}px) {
        width: 140px;
        height: 140px;
        min-width: 140px;
    }
`

const ImageContainerTopLines = styled.div`
    position: absolute;
    width: 50%;
    height: 50%;
    top: -12%;
    left: -25%;
`

const ImageContainerBottomLines = styled.div`
    position: absolute;
    width: 50%;
    height: 50%;
    bottom: -25%;
    right: -25%;
`

const TextContainer = styled.div`
    font-family: "Noto Sans JP";
    color: #1c1c1f;

    @media screen and (min-width: ${Sizes.sm}px) {
        padding: 0 15px;
    }
`

const SpeakerName = styled.h4`
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 10px;
    font-family: "Noto Sans JP";
`

const SpeakerContent = styled.p`
    font-family: "Noto Sans JP";
    color: #1c1c1f;
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: 0.6px;
`

const Title = styled.div`
    font-family: "Noto Sans JP";
    color: #1c1c1f;
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: 0.6px;
    word-break: break-word;
`

interface Props {
    image: any
    name: string
    content: string
    title?: string
}


const Speaker = ({image, title, name, content}: Props) => {
    return (
        <Wrapper>
            <ImageContainer>
                <ImageContainerTopLines>
                    <Stripes style={{ transform: "rotateZ(180deg)" }}  />
                </ImageContainerTopLines>
                <img src={image} style={{marginBottom: 0, display: 'block'}}/>
                <ImageContainerBottomLines>
                    <Stripes/>
                </ImageContainerBottomLines>
            </ImageContainer>
            <TextContainer>
                <Title>{ title }</Title>
                <SpeakerName>{ name }</SpeakerName>
                <SpeakerContent>{ content }</SpeakerContent>
            </TextContainer>
        </Wrapper>
    )
}

export default Speaker
