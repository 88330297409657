import React from "react"
import { Link } from "gatsby"
import { Leaf } from "./styles"
import Icon from "../Home/Icons/Icon"

const NavIcon = () => (
  <Leaf>
    <div>
      <Link to="/">
        <Icon />
      </Link>
    </div>
  </Leaf>
)

export default NavIcon
