import React from "react"

interface Props {
  style?: any
}

const Stripes = ({ style }: Props) => (
  <svg viewBox="0 0 1920 1080" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve"
  style={{
    fillRule: 'evenodd', 
    clipRule: 'evenodd', 
    strokeLinejoin: 'round', 
    strokeMiterlimit: 2,
    position: 'absolute',
    top: '-50%',
    bottom: '-50%',
    height: '200%',
  }}>
    <g>
        <g id="Group_46_" transform="matrix(0.866025,0.5,-0.5,0.866025,6633.34,-789.917)">
            <g id="Rectangle-Copy_196_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1372.26,8577.32)">
                <rect x="-3879.99" y="2810.68" width="109.01" height="1951.45" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-2_121_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1447.53,8354.22)">
                <rect x="-3835.98" y="2671.58" width="109.01" height="1951.45" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-4_121_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1522.26,8131.23)">
                <rect x="-3791.73" y="2532.73" width="109.01" height="1951.45" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-3_196_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1597.65,7908.68)">
                <rect x="-3747.96" y="2393.86" width="109.01" height="1951.45" style={{fill:'rgb(37,40,42)'}}/>
            </g>
        </g>
        <g id="Group_45_" transform="matrix(0.866025,0.5,-0.5,0.866025,6633.34,-789.917)">
            <g id="Rectangle-Copy_195_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-498.993,7912.3)">
                <rect x="-4405.53" y="3701.08" width="1951.45" height="109.01" style={{fill:'rgb(5,171,248)'}}/>
            </g>
            <g id="Rectangle-Copy-2_120_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-702.78,8013.26)">
                <rect x="-4548.01" y="3669.65" width="1951.45" height="109.01" style={{fill:'rgb(5,171,248)'}}/>
            </g>
            <g id="Rectangle-Copy-4_120_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-906.118,8114.52)">
                <rect x="-4690.38" y="3638.55" width="1951.45" height="109.01" style={{fill:'rgb(5,171,248)'}}/>
            </g>
            <g id="Rectangle-Copy-3_195_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-1109.61,8215.03)">
                <rect x="-4832.52" y="3607.01" width="1951.45" height="109.01" style={{fill:'rgb(5,171,248)'}}/>
            </g>
        </g>
        <g id="Group-2_36_" transform="matrix(0.866025,0.5,-0.5,0.866025,5890.68,-249.73)">
            <g id="Rectangle_150_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1744.06,6094.43)">
                <rect x="-3104.71" y="1552.89" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy_194_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1739.48,6108.45)">
                <rect x="-3107.55" y="1561.58" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-3_194_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1734.35,6122.59)">
                <rect x="-3110.16" y="1570.52" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-2_119_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1729.88,6137.16)">
                <rect x="-3113.26" y="1579.45" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-7_75_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1724.86,6151.84)">
                <rect x="-3116.13" y="1588.62" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-6_75_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1720.28,6165.87)">
                <rect x="-3118.97" y="1597.31" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-5_75_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1715.15,6180.01)">
                <rect x="-3121.58" y="1606.26" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-4_119_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1710.57,6194.03)">
                <rect x="-3124.42" y="1614.95" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle_149_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1705.55,6208.71)">
                <rect x="-3127.29" y="1624.13" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy_193_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1700.53,6223.4)">
                <rect x="-3130.15" y="1633.31" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-3_193_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-1695.95,6237.42)">
                <rect x="-3132.99" y="1642" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
        </g>
        <g id="Group-2-Copy-3_39_" transform="matrix(0.866025,0.5,-0.5,0.866025,8136.21,-52.2299)">
            <g id="Rectangle_148_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-3637.29,10532.9)">
                <rect x="-5675.82" y="3079.17" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy_192_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-3632.7,10546.9)">
                <rect x="-5678.66" y="3087.86" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-3_192_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-3627.57,10561)">
                <rect x="-5681.27" y="3096.8" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-2_118_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-3623.1,10575.6)">
                <rect x="-5684.37" y="3105.73" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-7_74_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-3618.08,10590.3)">
                <rect x="-5687.23" y="3114.9" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-6_74_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-3613.5,10604.3)">
                <rect x="-5690.08" y="3123.59" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-5_74_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-3608.37,10618.4)">
                <rect x="-5692.69" y="3132.54" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-4_118_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-3603.79,10632.5)">
                <rect x="-5695.53" y="3141.23" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle_147_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-3598.77,10647.1)">
                <rect x="-5698.39" y="3150.41" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy_191_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-3593.76,10661.8)">
                <rect x="-5701.26" y="3159.59" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
            <g id="Rectangle-Copy-3_191_" transform="matrix(-0.3022,0.9532,-0.9532,-0.3022,-3589.17,10675.9)">
                <rect x="-5704.1" y="3168.28" width="4.15" height="1711.97" style={{fill:'rgb(37,40,42)'}}/>
            </g>
        </g>
        <g id="Group-2-Copy-3_38_" transform="matrix(0.866025,0.5,-0.5,0.866025,8136.21,-52.2299)">
            <g id="Rectangle_146_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-2405.03,9397.77)">
                <rect x="-5835.89" y="3730.12" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy_190_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-2392.32,9391.32)">
                <rect x="-5826.95" y="3732" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-3_190_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-2379.17,9385.17)">
                <rect x="-5817.89" y="3734.21" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-2_117_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-2366.16,9378.27)">
                <rect x="-5808.62" y="3735.99" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-7_73_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-2352.7,9371.66)">
                <rect x="-5799.24" y="3738.1" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-6_73_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-2339.99,9365.21)">
                <rect x="-5790.29" y="3739.98" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-5_73_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-2326.84,9359.06)">
                <rect x="-5781.24" y="3742.19" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-4_117_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-2314.13,9352.61)">
                <rect x="-5772.29" y="3744.08" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle_145_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-2300.67,9346.01)">
                <rect x="-5762.91" y="3746.18" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy_189_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-2287.22,9339.41)">
                <rect x="-5753.53" y="3748.29" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-3_189_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-2274.51,9332.96)">
                <rect x="-5744.58" y="3750.18" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
        </g>
        <g id="Group-2_35_" transform="matrix(0.866025,0.5,-0.5,0.866025,5890.68,-249.73)">
            <g id="Rectangle_144_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-1611.27,7195.62)">
                <rect x="-4553.87" y="2948.09" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy_188_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-1598.56,7189.17)">
                <rect x="-4544.92" y="2949.97" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-3_188_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-1585.4,7183.01)">
                <rect x="-4535.87" y="2952.18" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-2_116_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-1572.4,7176.11)">
                <rect x="-4526.59" y="2953.96" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-7_72_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-1558.94,7169.51)">
                <rect x="-4517.21" y="2956.07" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-6_72_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-1546.23,7163.06)">
                <rect x="-4508.26" y="2957.95" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-5_72_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-1533.08,7156.91)">
                <rect x="-4499.21" y="2960.17" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-4_116_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-1520.37,7150.46)">
                <rect x="-4490.27" y="2962.05" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle_143_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-1506.91,7143.86)">
                <rect x="-4480.88" y="2964.16" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy_187_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-1493.45,7137.26)">
                <rect x="-4471.5" y="2966.27" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
            <g id="Rectangle-Copy-3_187_" transform="matrix(-0.2149,0.9766,-0.9766,-0.2149,-1480.75,7130.81)">
                <rect x="-4462.56" y="2968.15" width="1711.97" height="4.15" style={{fill:'rgb(254,80,0)'}}/>
            </g>
        </g>
        <path d="M764.21,1136.31L1196.98,662.52" style={{fill: 'none', fillRule: 'nonzero'}}/>
    </g>
</svg>

  
)

export default Stripes
