import styled from "styled-components"
import { Sizes } from "@/utils/vars"

export const Root = styled.div`
  overflow: hidden;
  position: relative;
`

export const BackgroundContainer = styled.div`
  height: 100%;
  background-image: linear-gradient(to right, rgb(0, 0, 0), rgb(22, 22, 22));
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  min-height: calc(100vh - 32px);

  @media only screen and (min-width: ${Sizes.lg}px) {
    padding: 50px;
    height: calc(100vh - 32px);
    max-height: 900px;
  }
`

export const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  max-width: 100vw;
  margin-top: 50px;
  z-index: 5;

  @media only screen and (min-width: ${Sizes.md}px) {
    margin-top: 0;
  }
`

export const TagLine = styled.div`
  margin: 32px 0;
  font-family: "Proxima Nova";
  font-size: 45px;
  font-weight: bold;
  letter-spacing: 1.13px;
  text-align: center;
`

export const Description = styled.p`
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-weight: bold;
  line-height: 2.14;
  letter-spacing: 0.35px;
  text-align: center;
`

export const TagLineVertical = styled.div`
  text-transform: uppercase;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-orientation: mixed;
  opacity: 0.7;
  -webkit-text-stroke: 1px #ffffff;
  -webkit-text-fill-color: transparent;
  font-family: "Bebas Neue Pro-Expanded Extra Bold";
  text-align: left;
  color: rgba(255, 255, 255, 0.7);
  position: absolute;
  right: 0;
  font-size: 10vh;
  top: 2vh;
  letter-spacing: 3px;
  z-index: -1;

  @media only screen and (max-height: 660px) {
    font-size: 64px;
  }
`
