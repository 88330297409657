import i18n from '@/utils/i18n'
import React from 'react'
import styled from 'styled-components'
import Speaker from './Speaker'

import MikeImage from "@/assets/images/homepage/events/innovationWeek/Mike-Sheetal.jpeg";
import ShinkoImage from "@/assets/images/homepage/events/innovationWeek/Shinko-Osada.jpg";
import ChristofferImage from "@/assets/images/homepage/events/innovationWeek/Christoffer-Melin.jpeg";
import SvenImage from "@/assets/images/homepage/events/innovationWeek/Sven-Rydell.jpeg";


const SpeakerContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: "Noto Sans JP";
`

const Title = styled.h3`
font-family: "Noto Sans JP";
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 15px;
`

const Date = styled.h3`
    font-weight: 900;
    color: #ff4d00;
    font-family: "Noto Sans JP";
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 0.7rem;
`

const Info = styled.p`
    width: 100%;
    max-width: 700px;
    color: #1c1c1f;
    font-size: 14px;
    white-space: pre-line;
    line-height: 1.25;
    letter-spacing: 0.6px;
`

const InnovationWeek = () => {

    return (
        <div>
            <Title>{i18n('home.events.conference.title')}</Title>
            <Date>{i18n('home.events.conference.date')}</Date>
            <Info>
                {i18n('home.events.conference.info.1')}
                <a href="https://social-innovation-week-shibuya.jp/events/event/2985/" target="_blank" rel="noopener noreferrer">{ i18n('home.events.conference.info.2') }</a>
                {i18n('home.events.conference.info.3')}
            </Info>
            <SpeakerContainer>
                <Speaker
                    image={MikeImage}
                    name={i18n('home.events.conference.speakers.1.name')}
                    title={i18n('home.events.conference.speakers.1.title')}
                    content={i18n('home.events.conference.speakers.1.content')}
                />
                <Speaker
                    image={ChristofferImage}
                    name={i18n('home.events.conference.speakers.2.name')}
                    title={i18n('home.events.conference.speakers.2.title')}
                    content={i18n('home.events.conference.speakers.2.content')}
                />
                <Speaker
                    image={ShinkoImage}
                    name={i18n('home.events.conference.speakers.3.name')}
                    title={i18n('home.events.conference.speakers.3.title')}
                    content={i18n('home.events.conference.speakers.3.content')}
                />
                <Speaker
                    image={SvenImage}
                    name={i18n('home.events.conference.speakers.4.name')}
                    title={i18n('home.events.conference.speakers.4.title')}
                    content={i18n('home.events.conference.speakers.4.content')}
                />
            </SpeakerContainer>
        </div>
    )
}

export default InnovationWeek
