import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Black, Green, White } from "@/utils/colors"

export const Link = styled(AnchorLink)`
  text-decoration: none;
  margin-right: auto;
  margin-bottom: 32px;
  padding-right: 32px;

  /* @media only screen and (min-width: 1366px) {
    position: absolute;
    right: -100px;
    padding-right: 0;
  } */
`

export const ButtonBase = styled.button`
  width: 50px;
  height: 50px;
  background-color: ${Black.normal};
  color: ${White.normal};
  border: 0;
  border-radius: 3px;
  outline: 0;
  cursor: pointer;

  transition: all 0.2s ease;

  &:hover {
    background-color: ${Green.normal};
    color: ${Black.normal};
  }
`

export const ScrollContainer = styled.div`
  margin: 0 auto;
  text-align: right;
  width: 100%;
  height: 50px;
  position: relative;
  margin-top: -50px;
  margin-bottom: 30px;
  max-width: 1000px;
  display: none;
`
