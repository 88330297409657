import Stripes from "@/components/Common/Backgrounds/Stripes"
import { White } from "@/utils/colors"
import i18n from "@/utils/i18n"
import { Sizes } from "@/utils/vars"
import React from "react"
import styled from "styled-components"
import Infographic from "./Infographic"

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 32px 0 64px 0;
`

const Title = styled.h2`
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 36px;
  font-weight: 900;
  color: #07a1ea;
  text-transform: uppercase;
  margin-bottom: 0.7rem;

  @media only screen and (min-width: ${Sizes.sm}px) {
    font-size: 36px;
  }
`

const SubTitle = styled.h3`
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 16px;
  color: #fff;
`

const ContentBody = styled.div`
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: ${Sizes.md}px) {
    flex-direction: row;
  }
`

const Content = styled.div`
  color: ${White.normal};
  font-family: "Noto Sans JP";
  text-align: left;

  h3 {
    font-size: 24px;
    font-weight: 900;
  }

  p {
    font-size: 18px;
  }
`

const ContentImage = styled.div`
  /* width: 75%; */
  margin: 0 auto;
  min-width: 250px;
  max-width: 600px;
`

const Paragraph = styled.p`
  font-size: 16px;
`

const StripeWrapperLeft = styled.div`
  position: absolute;
  width: 400px;
  left: -280px;
  top: -80px;
  z-index: -1;

  @media only screen and (min-width: ${Sizes.xs}px) {
    top: -80px;
  }

  @media only screen and (min-width: ${Sizes.sm}px) {
    width: 800px;
    left: -550px;
    top: -250px;
  }

  @media only screen and (min-width: ${Sizes.md}px) {
    width: 800px;
    left: -550px;
    top: -250px;
  }

  @media only screen and (min-width: ${Sizes.lg}px) {
    width: 1200px;
    left: -850px;
    top: -400px;
  }
`

const StripeWrapperRight = styled.div`
  position: absolute;
  width: 400px;
  right: -280px;
  bottom: -80px;
  z-index: -1;

  @media only screen and (min-width: ${Sizes.xs}px) {
    bottom: -80px;
  }

  @media only screen and (min-width: ${Sizes.sm}px) {
    width: 800px;
    right: -550px;
    bottom: -250px;
  }

  @media only screen and (min-width: ${Sizes.md}px) {
    width: 800px;
    right: -550px;
    bottom: -250px;
  }

  @media only screen and (min-width: ${Sizes.lg}px) {
    width: 1200px;
    right: -850px;
    bottom: -400px;
  }
`

const Info = () => {
  return (
    <Wrapper id="homeInfo">
      <StripeWrapperLeft>
        <Stripes />
      </StripeWrapperLeft>
      <StripeWrapperRight>
        <Stripes style={{ transform: "rotateZ(180deg)" }} />
      </StripeWrapperRight>
      <Title>{i18n("home.info.title")}</Title>
      <SubTitle>{i18n("home.info.subtitle")}</SubTitle>
      <h2 style={{ textAlign: "center" }}>
        {i18n("home.info.content.header")}
      </h2>
      <ContentBody>
        <Content>
          <Paragraph>
            {i18n("home.info.content.p1")}
            <br />
            {i18n("home.info.content.p1b")}
          </Paragraph>
          <Paragraph>{i18n("home.info.content.p2")}</Paragraph>
        </Content>
        <ContentImage>
          <Infographic />
        </ContentImage>
      </ContentBody>
    </Wrapper>
  )
}

export default Info
