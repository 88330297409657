import Stripes from "@/components/Common/Backgrounds/Stripes"
import InnovationWeek from "@/components/Content/Homepage/InnovationWeek"
import ShibuyaCup from "@/components/Content/Homepage/ShibuyaCup"
import i18n from "@/utils/i18n"
import { Sizes } from "@/utils/vars"
import React from "react"
import styled from "styled-components"
import Event from "./Event"
import innovationWeekLogo from "@/assets/images/homepage/events/innovationWeek/logo_1.png"

const schedule = require("./../../Content/Homepage/schedule.json")

const Root = styled.div`
  padding: 128px 15px;
  margin-top: -64px;

  @media only screen and (min-width: ${Sizes.md}px) {
    padding: 128px 32px;
  }

  @media only screen and (min-width: ${Sizes.lg}px) {
    padding: 128px 64px;
  }
  position: relative;
`

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 5.8px 9.3px 6px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding: 32px;
  z-index: 3;
  position: relative;

  margin: -128px auto;

  @media only screen and (min-width: ${Sizes.md}px) {
    margin: -128px auto;
  }
`

const Title = styled.h2`
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 36px;
  font-weight: 900;
  color: #07a1ea;
  text-transform: uppercase;
  margin-bottom: 0.7rem;

  @media only screen and (min-width: ${Sizes.md}px) {
    font-size: 36px;
  }
`

const SubTitle = styled.h3`
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 16px;
  font-weight: bold;
  color: #1c1c1f;
`

const Background = styled.div`
  background-image: linear-gradient(to right, #05abf8, #d82eca, #fe5000);
  position: absolute;
  top: -64px;
  left: 0;
  right: 0;
  bottom: -64px;
  z-index: 2;
  clip-path: polygon(0% 100%, 100% calc(100% - 128px), 100% 0%, 0% 128px);
`

const StripeWrapperLeft = styled.div`
  position: absolute;
  width: 400px;
  left: -280px;
  bottom: -92px;
  z-index: 1;

  @media only screen and (min-width: ${Sizes.xs}px) {
    bottom: -92px;
  }

  @media only screen and (min-width: ${Sizes.sm}px) {
    width: 800px;
    left: -550px;
    bottom: -218px;
  }

  @media only screen and (min-width: ${Sizes.md}px) {
    width: 800px;
    left: -550px;
    bottom: -218px;
  }

  @media only screen and (min-width: ${Sizes.lg}px) {
    width: 1200px;
    left: -838px;
    bottom: -320px;
  }
`

const Events = () => {
  return (
    <Root>
      <Wrapper id="homeEvents">
        <Title>{i18n("home.events.title")}</Title>
        <SubTitle>{i18n("home.events.subtitle")}</SubTitle>
        <Event
          title="SOCIAL INNOVATION WEEK SHIBUYA"
          date="November 7, 2020 - November 15, 2020"
          year="2020"
          schedule={[<InnovationWeek key={'0'}/>, <ShibuyaCup key={'1'}/>].map(k => (k))}
          headerImage={innovationWeekLogo}
        >
          <p>{i18n("home.events.event.description.p1")}</p>
          <p>{i18n("home.events.event.description.p2")}</p>
        </Event>
      </Wrapper>
      <Background />
      <StripeWrapperLeft>
        <Stripes />
      </StripeWrapperLeft>
    </Root>
  )
}

export default Events
