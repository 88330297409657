
import i18n from '@/utils/i18n'
import { Sizes } from '@/utils/vars'
import React from 'react'
import styled from 'styled-components'
import Player from './Player'
import Speaker from './Speaker'
import Logo from './Logo'
import Stripes from './Stripes'

import Caster1Image from "@/assets/images/homepage/events/shibuyaCup/casters/Katsudion.png";
import Caster2Image from "@/assets/images/homepage/events/shibuyaCup/casters/Lillebelt.png";
import Caster3Image from "@/assets/images/homepage/events/shibuyaCup/casters/Jaeger.png";
import Caster4Image from "@/assets/images/homepage/events/shibuyaCup/casters/Recruit.png";

import Speaker1Image from "@/assets/images/homepage/events/shibuyaCup/Picture_KeitaHara.jpg";
import Speaker2Image from "@/assets/images/homepage/events/shibuyaCup/Yoshimura.jpg";

import LolPlayer1Image from "@/assets/images/homepage/events/shibuyaCup/syaruru.png";
import LolPlayer2Image from "@/assets/images/homepage/events/shibuyaCup/tanuki.png";
import LolPlayer3Image from "@/assets/images/homepage/events/shibuyaCup/raijin.png";
import LolPlayer4Image from "@/assets/images/homepage/events/shibuyaCup/Roki.png";
import LolPlayer5Image from "@/assets/images/homepage/events/shibuyaCup/Akabuff.png";

const SpeakerContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: "Noto Sans JP";
`

const Title = styled.h3`
font-family: "Noto Sans JP";
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 15px;
`

const Date = styled.h3`
    font-weight: 900;
    color: #ff4d00;
    font-family: "Noto Sans JP";
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 0.7rem;
`

const ScheduleSubheader = styled.h4`
    font-weight: bold;
    color: #ff4d00;
    font-family: "Noto Sans JP";
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0.7rem;
    letter-spacing: 0.75px;
    margin-bottom: 0.1rem;
    display: block;
    width: 100%;
`

const Content = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const TextContent = styled.p`
    color: #1c1c1f;
    font-size: 14px;
    width: 100%;
    max-width: 700px;
    white-space: pre-line;
    line-height: 1.25;
    letter-spacing: 0.6px;

    @media only screen and (min-width: ${Sizes.md}px) {
        min-width: 50%;
    }
`

const PlayerContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    margin-bottom: 15px;
`

const Splash = styled.div`
    width:100%;
    background-color: #F5F7F6;
    position: relative;
    overflow: hidden;
    margin: 30px 0;
    height: 200px;

    > * {
        height:100%;
    }

    > svg {
        &:first-child {
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
        }

        &:nth-child(2) {
            z-index: 1;
            left: -60%;

            @media screen and (min-width: ${Sizes.md}px) {
                right: 0;
                left: initial;
            }
        }
    }

    @media only screen and (min-width: ${Sizes.sm}px) {
        height: 300px;
    }
`

const ListPlayer = styled.li`
    margin-bottom: 0;
`

const ShibuyaCup = () => {

    return (
        <div>
            <Splash><Logo/><Stripes /></Splash>
            <Title>{i18n('home.events.shibuyaCup.title')}</Title>
            <Date>{i18n('home.events.shibuyaCup.date')}</Date>
            <Content>
                <TextContent>
                    {
                        `${i18n('home.events.shibuyaCup.info')}

                        ${i18n('home.events.shibuyaCup.info2')} `
                    }
                    <a href={String(process.env.GATSBY_SOCIAL_MEDIA_YOUTUBE_URL)} target="_blank" rel="noopener noreferrer">{i18n("home.landing.socialLinks.youtube")}</a>
                    {` / `}
                    <a href={String(process.env.GATSBY_SOCIAL_MEDIA_TWITCH_URL)} target="_blank" rel="noopener noreferrer">{i18n("home.landing.socialLinks.twitch")}</a>
                </TextContent>
                <Date>{i18n('home.events.shibuyaCup.scheduleTimes.1.title')}</Date>
                <SpeakerContainer>
                    <Speaker
                        image={Speaker1Image}
                        name={i18n('home.events.shibuyaCup.speakers.1.name')}
                        title={i18n('home.events.shibuyaCup.speakers.1.title')}
                        content={i18n('home.events.shibuyaCup.speakers.1.content')}
                    />
                    <Speaker
                        image={Speaker2Image}
                        name={i18n('home.events.shibuyaCup.speakers.2.name')}
                        title={i18n('home.events.shibuyaCup.speakers.2.title')}
                        content={i18n('home.events.shibuyaCup.speakers.2.content')}
                    />
                </SpeakerContainer>
                <Date>{i18n('home.events.shibuyaCup.scheduleTimes.2.title')}</Date>
                <ScheduleSubheader>{i18n('home.events.shibuyaCup.scheduleTimes.2.info1')}</ScheduleSubheader>
                <PlayerContainer>
                    <Player
                        image={Caster1Image}
                        name={i18n('home.events.shibuyaCup.players.casters.1')}/>
                    <Player
                        image={Caster2Image}
                        name={i18n('home.events.shibuyaCup.players.casters.2')}/>
                    <Player
                        image={Caster3Image}
                        name={i18n('home.events.shibuyaCup.players.casters.3')}/>
                    <Player
                        image={Caster4Image}
                        name={i18n('home.events.shibuyaCup.players.casters.4')}/>
                </PlayerContainer>
                <ScheduleSubheader>{i18n('home.events.shibuyaCup.scheduleTimes.2.info2')}</ScheduleSubheader>
                <ul style={{paddingTop: '15px'}}>
                    <ListPlayer>{i18n('home.events.shibuyaCup.players.kdgn1.1')}</ListPlayer>
                    <ListPlayer>{i18n('home.events.shibuyaCup.players.kdgn1.2')}</ListPlayer>
                    <ListPlayer>{i18n('home.events.shibuyaCup.players.kdgn1.3')}</ListPlayer>
                    <ListPlayer>{i18n('home.events.shibuyaCup.players.kdgn1.4')}</ListPlayer>
                    <ListPlayer>{i18n('home.events.shibuyaCup.players.kdgn1.5')}</ListPlayer>
                    <ListPlayer>{i18n('home.events.shibuyaCup.players.kdgn1.6')}</ListPlayer>
                </ul>
                <ScheduleSubheader>{i18n('home.events.shibuyaCup.scheduleTimes.2.info3')}</ScheduleSubheader>
                <PlayerContainer>
                    <Player
                        image={LolPlayer1Image}
                        name={i18n('home.events.shibuyaCup.players.lol.1')}/>
                    <Player
                        image={LolPlayer2Image}
                        name={i18n('home.events.shibuyaCup.players.lol.2')}/>
                    <Player
                        image={LolPlayer3Image}
                        name={i18n('home.events.shibuyaCup.players.lol.3')}/>
                    <Player
                        image={LolPlayer4Image}
                        name={i18n('home.events.shibuyaCup.players.lol.4')}/>
                    <Player
                        image={LolPlayer5Image}
                        name={i18n('home.events.shibuyaCup.players.lol.5')}/>
                </PlayerContainer>
            </Content>
        </div>
    )
}

export default ShibuyaCup
