import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import i18n from "@/utils/i18n"
import { Link, ButtonBase, Text, Icons } from "./styles"

const ScrollDownButton = () => (
  <Link href="#homeInfo" offset="32">
    <ButtonBase>
      <Text>{i18n("home.landing.scrollDownButton")}</Text>
      <Icons>
        <FontAwesomeIcon icon={faChevronDown} />
        <FontAwesomeIcon icon={faChevronDown} />
        <FontAwesomeIcon icon={faChevronDown} />
      </Icons>
    </ButtonBase>
  </Link>
)

export default ScrollDownButton
