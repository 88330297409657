import Stripes from "@/components/Common/Backgrounds/Stripes"
import i18n from "@/utils/i18n"
import { Sizes } from "@/utils/vars"
import React from "react"
import styled from "styled-components"
import Sponsor from "./Sponsor"

const Root = styled.div`
  /* padding: 128px 15px; */
  padding: 64px 15px;
  margin-top: -128px;

  @media only screen and (min-width: ${Sizes.md}px) {
    /* padding: 128px 32px; */
    padding: 64px 32px;
  }

  @media only screen and (min-width: ${Sizes.lg}px) {
    /* padding: 128px 64px; */
    padding: 64px 64px;
  }
  position: relative;
`

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 64px auto 0 auto;
`

const Title = styled.h2`
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 36px;
  font-weight: 900;
  color: #07a1ea;
  text-transform: uppercase;
  margin-bottom: 0.7rem;

  @media only screen and (min-width: ${Sizes.md}px) {
    font-size: 36px;
  }
`

const SubTitle = styled.h3`
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 16px;
  color: #fff;
`

const SponsorGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const StripeWrapperLeft = styled.div`
  position: absolute;
  width: 400px;
  left: -280px;
  top: -80px;
  z-index: -1;

  @media only screen and (min-width: ${Sizes.xs}px) {
    top: -80px;
  }

  @media only screen and (min-width: ${Sizes.sm}px) {
    width: 800px;
    left: -550px;
    top: -250px;
  }

  @media only screen and (min-width: ${Sizes.md}px) {
    width: 800px;
    left: -550px;
    top: -250px;
  }

  @media only screen and (min-width: ${Sizes.lg}px) {
    width: 1200px;
    left: -850px;
    top: -400px;
  }
`

const Sponsors = () => {
  return (
    <Root>
      <Wrapper id="homeSponsors">
        <StripeWrapperLeft>
          <Stripes />
        </StripeWrapperLeft>
        {/* <Title>{i18n("home.sponsors.title")}</Title>
        <SubTitle>{i18n("home.sponsors.subtitle")}</SubTitle>
        <SponsorGrid>
          <Sponsor />
          <Sponsor />
          <Sponsor />
          <Sponsor />
          <Sponsor />
          <Sponsor />
          <Sponsor />
          <Sponsor />
          <Sponsor />
        </SponsorGrid> */}
      </Wrapper>
    </Root>
  )
}

export default Sponsors
