import styled, { keyframes } from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { White, Green } from "@/utils/colors"
import { Sizes } from "@/utils/vars"

export const BounceAnimation = keyframes`
  0% { transform: translateY(-10px) }
  50% { transform: translateY(10px) }
  100% { transform: translateY(-10px) }
`

export const Link = styled(AnchorLink)`
  text-decoration: none;
  margin-right: auto;
  margin-bottom: 32px;
  animation: ${BounceAnimation} 2s infinite ease-in-out;
  z-index: 6;

  @media only screen and (min-width: ${Sizes.md}px) {
    position: absolute;
    bottom: 0;
    left: 50px;
  }
`

export const Icons = styled.div`
  width: 30px;
  border: 2px solid ${White.normal};
  display: flex;
  flex-direction: column;
  padding: 4px 6px;
`

export const ButtonBase = styled.button`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  color: ${White.normal};
  transition: all 0.25s ease;

  &:hover {
    transition: all 0.25s ease;
    color: ${Green.normal};

    ${Icons} {
      transition: border 0.25s ease;
      border: 2px solid ${Green.normal};
    }
  }
`

export const Text = styled.span`
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  padding: 16px 4px;
  font-family: "Proxima Nova";
  font-weight: bold;
  width: 32px;
`
