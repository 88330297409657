import React from "react"

interface Props {
  style?: any
}

const Infographic = ({ style }: Props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="64 64 900 900"
    xmlSpace="preserve"
    style={style}
  >
    <circle style={{ fill: "#2F2F2F" }} cx="512.29" cy="512.29" r="416.86" />
    <g>
      <g>
        <path
          style={{ fill: "#FFFFFF" }}
          d="M566.89,307.92c0.5,0.13,0.99,0.27,1.49,0.4c0.28,0.08,0.56,0.15,0.83,0.23
			c15.76,4.39,30.78,10.57,44.82,18.29c0.06,0.03,0.11,0.06,0.17,0.09c2.76,1.52,5.49,3.1,8.17,4.74c0.56,0.34,1.11,0.69,1.66,1.04
			c2.18,1.36,4.34,2.74,6.46,4.18c0.99,0.67,1.97,1.36,2.96,2.05c0.79,0.55,1.57,1.11,2.35,1.67c0.34,0.24,0.67,0.49,1.01,0.74
			c0.2,0.15,0.4,0.29,0.6,0.44c1.64,1.21,3.27,2.44,4.89,3.69c0.45,0.35,0.9,0.71,1.35,1.07c0.66,0.52,1.32,1.05,1.97,1.58
			c1.65,1.34,3.27,2.7,4.88,4.09c0.22,0.19,0.43,0.38,0.65,0.57c0.93,0.81,1.86,1.62,2.77,2.44c1.41,1.27,2.8,2.57,4.18,3.89
			c0.1,0.09,0.2,0.19,0.3,0.29c1.16,1.11,2.32,2.22,3.45,3.35c1.12,1.12,2.22,2.26,3.32,3.41c0.1,0.1,0.19,0.2,0.29,0.3
			c1.33,1.39,2.64,2.79,3.92,4.21c0.81,0.9,1.6,1.81,2.4,2.73c0.19,0.22,0.39,0.44,0.58,0.66c1.4,1.62,2.78,3.26,4.12,4.92
			c0.52,0.64,1.03,1.29,1.54,1.93c0.36,0.45,0.72,0.9,1.07,1.36c1.26,1.62,2.5,3.26,3.72,4.92c0.15,0.2,0.3,0.41,0.44,0.61
			c0.23,0.32,0.47,0.63,0.7,0.95c0.56,0.78,1.12,1.57,1.68,2.36c0.69,0.99,1.39,1.98,2.06,2.98c1.43,2.12,2.81,4.28,4.17,6.45
			c0.34,0.55,0.7,1.1,1.04,1.66c1.64,2.69,3.22,5.42,4.75,8.19c0.03,0.05,0.06,0.1,0.08,0.15c7.73,14.06,13.91,29.09,18.3,44.86
			c0.07,0.25,0.13,0.5,0.2,0.75c0.82,2.97,1.58,5.96,2.26,8.98c0.14,0.63,0.27,1.26,0.41,1.89c0.58,2.64,1.12,5.29,1.59,7.96
			c0.18,0.99,0.33,1.98,0.49,2.98c0.33,1.99,0.62,3.99,0.89,6c0.22,1.64,0.42,3.28,0.6,4.92c0.16,1.45,0.3,2.91,0.43,4.37
			c0.19,2.13,0.35,4.26,0.48,6.39c0.07,1.22,0.13,2.45,0.19,3.68c0.06,1.36,0.14,2.73,0.17,4.09c0.02,1.02,0,2.04,0.01,3.06
			c0.01,0.62,0.02,1.23,0.02,1.84s-0.02,1.23-0.02,1.84c-0.01,1.02,0.01,2.04-0.01,3.06c-0.03,1.36-0.11,2.73-0.17,4.09
			c-0.05,1.23-0.11,2.46-0.19,3.68c-0.13,2.13-0.28,4.26-0.48,6.39c-0.13,1.46-0.27,2.92-0.43,4.37c-0.18,1.64-0.38,3.28-0.6,4.92
			c-0.27,2.01-0.57,4.01-0.89,6c-0.16,0.99-0.32,1.98-0.49,2.98c-0.48,2.67-1.02,5.32-1.59,7.96c-0.14,0.63-0.27,1.26-0.41,1.89
			c-0.69,3.02-1.45,6.01-2.26,8.98c-0.07,0.25-0.13,0.5-0.2,0.75c-4.4,15.77-10.57,30.8-18.3,44.86c-0.03,0.05-0.06,0.1-0.08,0.15
			c-1.52,2.77-3.1,5.5-4.75,8.19c-0.28,0.46-0.56,0.91-0.85,1.37l30.28,113.03l112.89-30.25c34.19-56.72,53.87-123.18,53.87-194.24
			c0-184.94-133.24-338.75-308.97-370.71l76.55,76.55L566.89,307.92z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M661.96,661.74c-0.03,0.03-0.07,0.07-0.1,0.1c-1.13,1.13-2.29,2.24-3.45,3.35c-0.1,0.1-0.2,0.19-0.3,0.29
			c-1.38,1.31-2.76,2.61-4.17,3.88c-0.91,0.82-1.84,1.63-2.77,2.44c-0.22,0.19-0.43,0.38-0.65,0.57c-1.61,1.39-3.23,2.75-4.88,4.09
			c-0.65,0.53-1.31,1.06-1.98,1.58c-0.45,0.36-0.9,0.72-1.36,1.07c-1.61,1.25-3.23,2.48-4.87,3.68c-0.2,0.15-0.41,0.3-0.61,0.44
			c-0.34,0.25-0.67,0.49-1.01,0.74c-0.78,0.56-1.57,1.12-2.35,1.67c-0.98,0.68-1.95,1.37-2.94,2.04c-2.13,1.44-4.3,2.83-6.49,4.2
			c-0.55,0.34-1.09,0.69-1.64,1.02c-2.69,1.64-5.42,3.22-8.19,4.75c-0.05,0.03-0.1,0.06-0.15,0.08
			c-14.05,7.73-29.07,13.9-44.84,18.3c-0.27,0.08-0.54,0.14-0.81,0.22c-2.95,0.81-5.92,1.57-8.93,2.25
			c-0.65,0.15-1.29,0.28-1.94,0.42c-2.62,0.57-5.25,1.11-7.91,1.58c-1.01,0.18-2.02,0.34-3.03,0.5c-1.96,0.32-3.92,0.61-5.89,0.88
			c-1.67,0.23-3.35,0.43-5.02,0.61c-1.43,0.16-2.86,0.3-4.29,0.43c-2.16,0.19-4.31,0.35-6.47,0.48c-1.2,0.07-2.4,0.13-3.61,0.18
			c-1.38,0.06-2.75,0.14-4.13,0.17c-1.02,0.02-2.05,0-3.08,0.01c-0.61,0.01-1.21,0.02-1.82,0.02c-0.61,0-1.21-0.02-1.82-0.02
			c-1.03-0.01-2.05,0.01-3.08-0.01c-1.38-0.03-2.75-0.11-4.13-0.17c-1.21-0.05-2.41-0.11-3.61-0.18c-2.16-0.13-4.31-0.29-6.47-0.48
			c-1.43-0.13-2.86-0.27-4.29-0.43c-1.68-0.19-3.35-0.39-5.02-0.61c-1.97-0.26-3.94-0.56-5.89-0.88c-1.01-0.17-2.02-0.32-3.03-0.5
			c-2.65-0.47-5.29-1.01-7.9-1.58c-0.65-0.14-1.3-0.27-1.94-0.42c-3-0.68-5.97-1.44-8.93-2.25c-0.27-0.07-0.54-0.14-0.81-0.22
			c-15.76-4.4-30.79-10.57-44.83-18.3c-0.05-0.03-0.11-0.06-0.16-0.09c-2.76-1.52-5.49-3.1-8.18-4.74c-0.56-0.34-1.1-0.69-1.66-1.04
			c-2.17-1.36-4.33-2.74-6.45-4.17c-1-0.67-1.99-1.37-2.97-2.06c-0.78-0.55-1.56-1.1-2.34-1.66c-0.34-0.24-0.67-0.49-1.01-0.74
			c-0.19-0.14-0.39-0.28-0.58-0.42c-1.65-1.21-3.29-2.45-4.91-3.71c-0.45-0.35-0.9-0.71-1.34-1.06c-0.66-0.52-1.32-1.05-1.97-1.58
			c-1.65-1.34-3.28-2.71-4.9-4.11c-0.21-0.18-0.42-0.37-0.64-0.56c-0.93-0.81-1.85-1.61-2.76-2.43c-1.42-1.28-2.81-2.58-4.2-3.9
			c-0.1-0.09-0.19-0.18-0.29-0.27c-1.16-1.1-2.31-2.21-3.44-3.34c-1.13-1.13-2.23-2.28-3.34-3.44c-0.09-0.09-0.18-0.19-0.27-0.28
			c-1.32-1.39-2.63-2.78-3.91-4.2c-0.82-0.91-1.62-1.84-2.43-2.76c-0.18-0.21-0.37-0.42-0.56-0.63c-1.4-1.62-2.77-3.25-4.11-4.91
			c-0.53-0.65-1.05-1.31-1.57-1.97c-0.35-0.45-0.71-0.89-1.06-1.34c-1.27-1.63-2.51-3.27-3.72-4.93c-0.14-0.19-0.28-0.38-0.41-0.57
			c-0.25-0.34-0.49-0.67-0.74-1.01c-0.56-0.77-1.11-1.55-1.66-2.33c-0.7-1-1.4-1.99-2.07-3c-1.43-2.11-2.8-4.26-4.15-6.43
			c-0.35-0.56-0.7-1.11-1.05-1.68c-1.64-2.68-3.21-5.4-4.73-8.16c-0.03-0.06-0.07-0.12-0.1-0.18c-0.33-0.6-0.66-1.2-0.98-1.81
			l-113.01-30.28L182.6,694.84C246.88,810.68,370.42,889.1,512.29,889.1c114.96,0,217.88-51.5,287-132.67l-104.45,27.99
			L661.96,661.74z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M307.99,567.18c-0.68-2.55-1.33-5.11-1.92-7.69c-0.15-0.66-0.29-1.32-0.43-1.98
			c-0.57-2.61-1.1-5.23-1.57-7.87c-0.18-1.02-0.34-2.04-0.51-3.07c-0.32-1.93-0.61-3.87-0.87-5.82c-0.23-1.7-0.43-3.4-0.62-5.1
			c-0.15-1.4-0.29-2.81-0.42-4.22c-0.2-2.18-0.36-4.36-0.49-6.55c-0.07-1.17-0.13-2.35-0.18-3.53c-0.06-1.39-0.14-2.78-0.17-4.17
			c-0.03-1.04,0-2.08-0.01-3.12c0-0.59-0.02-1.18-0.02-1.78c0-0.6,0.02-1.19,0.02-1.78c0.01-1.04-0.01-2.08,0.01-3.12
			c0.03-1.39,0.11-2.78,0.17-4.17c0.05-1.18,0.11-2.36,0.18-3.53c0.13-2.18,0.29-4.37,0.49-6.55c0.13-1.41,0.26-2.82,0.42-4.22
			c0.19-1.7,0.39-3.4,0.62-5.1c0.26-1.95,0.55-3.89,0.87-5.82c0.17-1.02,0.33-2.05,0.51-3.07c0.47-2.64,1.01-5.26,1.57-7.87
			c0.14-0.66,0.28-1.32,0.43-1.98c0.68-2.99,1.44-5.96,2.24-8.9c0.08-0.28,0.15-0.56,0.23-0.83c4.39-15.76,10.57-30.77,18.29-44.82
			c0.03-0.06,0.07-0.12,0.1-0.18c1.52-2.76,3.09-5.48,4.73-8.16c0.34-0.56,0.7-1.12,1.05-1.68c1.35-2.17,2.73-4.31,4.15-6.43
			c0.68-1.01,1.38-2,2.07-3c0.55-0.78,1.1-1.56,1.66-2.33c0.24-0.34,0.49-0.67,0.74-1.01c0.14-0.19,0.27-0.38,0.41-0.56
			c1.22-1.66,2.46-3.31,3.73-4.94c0.35-0.45,0.7-0.89,1.05-1.33c0.52-0.66,1.04-1.32,1.57-1.97c1.34-1.65,2.72-3.29,4.11-4.91
			c0.18-0.21,0.37-0.42,0.56-0.63c0.8-0.93,1.61-1.85,2.43-2.76c1.28-1.42,2.59-2.82,3.91-4.2c0.09-0.09,0.18-0.19,0.27-0.28
			c1.1-1.16,2.21-2.31,3.34-3.44c1.13-1.13,2.28-2.23,3.44-3.34c0.09-0.09,0.19-0.18,0.28-0.27c1.39-1.32,2.78-2.63,4.2-3.91
			c0.91-0.82,1.84-1.62,2.76-2.43c0.21-0.18,0.42-0.37,0.63-0.56c1.62-1.4,3.25-2.77,4.91-4.11c0.65-0.53,1.31-1.05,1.97-1.57
			c0.44-0.35,0.89-0.71,1.34-1.06c1.63-1.27,3.27-2.51,4.93-3.72c0.19-0.14,0.38-0.28,0.57-0.41c0.34-0.25,0.67-0.49,1.01-0.74
			c0.77-0.56,1.55-1.11,2.33-1.66c1-0.7,1.99-1.4,3-2.07c2.11-1.43,4.26-2.8,6.43-4.15c0.56-0.35,1.11-0.7,1.68-1.05
			c2.68-1.64,5.4-3.21,8.16-4.73c0.06-0.03,0.12-0.07,0.18-0.1c14.05-7.72,29.06-13.9,44.82-18.29c0.28-0.08,0.56-0.15,0.83-0.23
			c2.94-0.81,5.91-1.56,8.9-2.24c0.66-0.15,1.32-0.29,1.98-0.43c2.61-0.57,5.23-1.1,7.87-1.57c1.02-0.18,2.05-0.34,3.07-0.51
			c1.93-0.31,3.87-0.61,5.82-0.87c1.7-0.23,3.4-0.43,5.1-0.62c1.4-0.15,2.81-0.29,4.22-0.42c2.18-0.2,4.37-0.36,6.55-0.49
			c1.17-0.07,2.35-0.13,3.53-0.18c1.39-0.06,2.78-0.14,4.17-0.17c1.04-0.03,2.08,0,3.12-0.01c0.59,0,1.18-0.02,1.78-0.02
			c0.6,0,1.19,0.02,1.78,0.02c1.04,0.01,2.08-0.01,3.12,0.01c0.58,0.01,1.17,0.03,1.75,0.05l82.74-82.74l-82.57-82.57
			c-2.27-0.04-4.54-0.09-6.82-0.09c-208.11,0-376.81,168.7-376.81,376.81c0,44.43,7.71,87.06,21.84,126.64l28.03-104.61
			L307.99,567.18z"
        />
      </g>
      <circle style={{ fill: "#00A9F1" }} cx="512.29" cy="512.29" r="172.66" />
      <g>
        <path
          style={{ fill: "none" }}
          d="M420.4,484.03c0-1.44,0-2.88-0.01-4.32c3.78-0.53,7.55-1.08,11.34-1.57c-3.78,0.49-7.56,1.04-11.34,1.57
			C420.4,481.15,420.4,482.59,420.4,484.03z"
        />
        <path
          style={{ fill: "none" }}
          d="M420.4,560.59c0.01,3.08,0.02,6.16,0.02,9.25c-0.93,0.35-1.9,0.49-2.88,0.6c0.97-0.11,1.94-0.25,2.88-0.6
			c1.49-0.08,2.97-0.27,4.44-0.49c-1.47,0.22-2.95,0.41-4.44,0.49C420.41,566.75,420.4,563.67,420.4,560.59
			c3.74-0.53,7.48-1.09,11.23-1.58C427.88,559.5,424.14,560.06,420.4,560.59z"
        />
        <path
          style={{ fill: "none" }}
          d="M410.28,500.06c3.37-0.46,6.74-0.91,10.12-1.37C417.02,499.14,413.65,499.6,410.28,500.06
			c0-1.71,0-3.42,0-5.13C410.28,496.64,410.28,498.35,410.28,500.06c-0.1,3.14-0.1,6.27,0,9.41v0
			C410.17,506.33,410.18,503.19,410.28,500.06z"
        />
        <path
          style={{ fill: "none" }}
          d="M420.43,507.96c-0.01-3.09-0.02-6.18-0.03-9.27c3.74-0.54,7.48-1.12,11.22-1.59
			c-3.75,0.47-7.48,1.05-11.22,1.59C420.4,501.78,420.42,504.87,420.43,507.96z"
        />
        <path
          style={{ fill: "none" }}
          d="M410.27,551.76c0.01,3.41,0.01,6.81,0.02,10.22C410.28,558.57,410.27,555.17,410.27,551.76
			C410.27,551.76,410.27,551.76,410.27,551.76z"
        />
        <path
          style={{ fill: "none" }}
          d="M420.39,541.63c-3.37,0.47-6.74,0.94-10.1,1.41c-0.01-2.78-0.05-5.55-0.03-8.33
			c-0.02,2.78,0.02,5.55,0.03,8.33v0C413.65,542.57,417.02,542.1,420.39,541.63z"
        />
        <path
          style={{ fill: "none" }}
          d="M420.43,550.17c-0.01-2.85-0.03-5.69-0.04-8.54c3.74-0.53,7.48-1.09,11.22-1.57
			c-3.75,0.48-7.48,1.04-11.22,1.57C420.4,544.48,420.41,547.33,420.43,550.17z"
        />
        <path
          style={{ fill: "none" }}
          d="M450.5,566.11c0-0.63-0.06-1.27,0.01-1.89C450.45,564.84,450.5,565.48,450.5,566.11
			c0,2.86,0.04,5.73-0.02,8.59C450.54,571.83,450.5,568.97,450.5,566.11z"
        />
        <path
          style={{ fill: "none" }}
          d="M562.25,459.28c-0.02,1.68-0.01,3.36-0.01,5.04c0,0.97-0.03,1.93,0.01,2.9c-0.04-0.96-0.01-1.93-0.01-2.9
			C562.24,462.64,562.23,460.96,562.25,459.28z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M396.14,557.82c1.93-1.3,3.38-3.01,3.74-5.33c0.62-4.01,0.36-8.05,0.16-12.08c-0.03-0.54-0.19-1.08-0.37-1.59
			c-0.87-2.54-3.33-4.07-5.89-3.7c-6.37,0.91-12.73,1.83-19.1,2.69c-0.85,0.11-1.01,0.44-1.01,1.2c0.02,15.53,0.02,31.07,0,46.6
			c0,0.85,0.11,1.15,1.11,1c5.74-0.85,11.49-1.6,17.23-2.43c1.23-0.18,2.51-0.17,3.66-0.82c3.11-1.74,4.43-4.5,4.5-7.92
			c0.06-3.07-0.03-6.15,0.03-9.22c0.07-3.61-0.99-6.47-4.75-7.69c-0.03-0.01-0.03-0.07-0.05-0.11c0.01-0.04,0-0.09,0.02-0.11
			C395.66,558.14,395.9,557.98,396.14,557.82z M388.66,576.32c-1.18,0.45-2.46,0.48-3.71,0.6c-0.47,0.04-0.36-0.48-0.36-0.79
			c-0.01-1.77,0-3.53,0-5.3c0-1.68,0.03-3.36-0.01-5.04c-0.02-0.69,0.18-0.98,0.9-1.01c0.84-0.04,1.67-0.2,2.49-0.35
			c0.86-0.16,1.31,0.18,1.32,1.03c0.01,3.24,0,6.47,0.01,9.71C389.3,575.7,389.09,576.16,388.66,576.32z M389.3,554.74
			c0,0.84-0.35,1.34-1.22,1.46c-0.87,0.12-1.75,0.25-2.62,0.4c-0.68,0.12-0.89-0.15-0.88-0.81c0.03-1.72,0.01-3.45,0.01-5.17
			c0-1.68,0.03-3.37-0.01-5.05c-0.02-0.67,0.18-1.02,0.87-1.09c0.88-0.09,1.75-0.23,2.62-0.35c0.87-0.12,1.23,0.28,1.23,1.14
			C389.28,548.43,389.29,551.59,389.3,554.74z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M578.8,488.16c-1.73-4.28-3.37-8.61-5.08-12.9c-0.27-0.67-0.19-1.03,0.44-1.44c2.41-1.57,3.57-3.91,3.64-6.7
			c0.11-4.29,0.04-8.59,0.02-12.88c-0.01-1.19-0.34-2.32-0.95-3.36c-1.08-1.87-3.29-2.92-5.28-2.64
			c-6.24,0.89-12.48,1.76-18.72,2.57c-1.11,0.14-1.53,0.46-1.52,1.68c0.06,7.66,0.03,15.32,0.03,22.98c0,7.7,0.02,15.4-0.02,23.11
			c0,0.99,0.2,1.33,1.26,1.16c2.82-0.45,5.65-0.83,8.49-1.17c0.88-0.11,1.17-0.41,1.16-1.33c-0.05-5.01-0.02-10.02-0.02-15.03
			c0-0.76,0.02-1.52-0.01-2.27c-0.02-0.47,0.28-0.53,0.62-0.63c0.36-0.1,0.57,0.04,0.69,0.35c0.13,0.31,0.23,0.63,0.34,0.95
			c1.81,5.29,3.62,10.57,5.4,15.86c0.21,0.63,0.49,0.88,1.18,0.76c1.78-0.3,3.57-0.51,5.35-0.83c1.09-0.2,2.61,0.27,3.17-0.66
			c0.5-0.85,0.19-2.21,0.14-3.34C579.06,490.99,579.37,489.57,578.8,488.16z M566.91,468.68c0,0.84-0.44,1.3-1.31,1.38
			c-1.08,0.1-2.5,0.73-3.14,0.26c-0.7-0.51-0.18-2.03-0.22-3.11c-0.04-0.96-0.01-1.93-0.01-2.9c0-1.68-0.01-3.36,0.01-5.04
			c0-0.29-0.22-0.77,0.25-0.85c1.2-0.21,2.4-0.41,3.61-0.49c0.6-0.04,0.81,0.46,0.81,1.05
			C566.91,462.21,566.93,465.44,566.91,468.68z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M541.93,515.45c0-0.85-0.1-1.19-1.1-1.01c-2.4,0.42-4.82,0.75-7.24,1.01c-1.04,0.11-1.27,0.55-1.26,1.53
			c0.04,6.23,0.03,12.46,0.01,18.68c0,0.39,0.24,1.02-0.24,1.11c-0.62,0.12-0.48-0.64-0.72-0.99c-0.05-0.07-0.05-0.16-0.07-0.24
			c-1.98-5.89-3.97-11.78-5.91-17.69c-0.29-0.88-0.66-1.21-1.6-1.05c-2.36,0.4-4.73,0.78-7.11,1.04c-1.03,0.11-1.29,0.47-1.28,1.47
			c0.04,7.7,0.02,15.4,0.02,23.1c0,7.62,0.02,15.23-0.03,22.85c-0.01,1.17,0.14,1.64,1.47,1.39c2.27-0.43,4.57-0.71,6.86-0.98
			c0.98-0.11,1.31-0.47,1.3-1.5c-0.05-6.27-0.03-12.54-0.01-18.81c0-0.37-0.26-0.98,0.31-1.07c0.57-0.09,0.5,0.58,0.62,0.94
			c2,5.97,4.01,11.94,5.94,17.94c0.33,1.01,0.77,1.3,1.8,1.12c2.32-0.41,4.64-0.82,6.98-1.03c1.12-0.1,1.25-0.53,1.25-1.48
			c-0.03-9.13-0.02-18.26-0.02-27.39C541.91,528.07,541.9,521.76,541.93,515.45z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M577.51,514.61c-0.66-2.94-3.37-4.82-6.15-4.41c-6.28,0.92-12.56,1.82-18.85,2.66
			c-0.98,0.13-1.23,0.43-1.23,1.39c0.04,7.74,0.02,15.49,0.02,23.23c0,7.62,0.02,15.24-0.02,22.86c-0.01,1.11,0.13,1.53,1.43,1.32
			c5.15-0.8,10.33-1.43,15.48-2.21c1.65-0.25,3.4-0.14,4.93-0.95c3.29-1.73,4.67-4.6,4.68-8.2c0.02-11.07,0.01-22.14-0.01-33.21
			C577.8,516.28,577.7,515.43,577.51,514.61z M566.9,523.35c-0.01,8.5,0,17,0,25.5c0,1.76-0.1,1.88-1.86,2.17
			c-2.83,0.47-2.83,0.47-2.83-2.43c0-4.25,0-8.5,0-12.75c0-4.8,0.02-9.59-0.02-14.39c-0.01-0.79,0.2-1.14,1-1.22
			C567.18,519.81,566.91,519.1,566.9,523.35z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M515.5,498.02c0.08,4.04,2.92,6.56,6.56,6.07c3.79-0.51,7.57-1.12,11.37-1.55c2.59-0.3,4.89-0.96,6.62-3.1
			c1.56-1.94,1.86-4.19,1.86-6.53c0.02-10.27,0.01-20.53,0-30.8c0-1.13-0.05-2.27-0.15-3.4c-0.3-3.57-3.06-5.78-6.58-5.3
			c-3.75,0.51-7.5,0.99-11.23,1.58c-1.47,0.23-2.98,0.21-4.34,1.08c-2.71,1.74-4.08,4.21-4.12,7.36
			c-0.07,5.72-0.02,11.44-0.02,17.16c0,0,0,0,0,0C515.47,486.41,515.39,492.22,515.5,498.02z M528.08,463.3
			c2.94-0.39,2.93-0.46,2.93,2.47c0,4.2,0,8.41,0,12.61c0,2.29-0.01,4.58-0.01,6.87c0,2.29,0,4.58,0.03,6.87
			c0.02,1.29-0.38,1.87-1.72,2.07c-2.96,0.45-2.95,0.52-2.95-2.48c0-8.78,0.02-17.57-0.02-26.35
			C526.34,464.07,526.75,463.48,528.08,463.3z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M505.54,525.08c-0.63-3.25-3.2-5.22-6.2-4.8c-3.79,0.53-7.58,1-11.36,1.6c-1.35,0.21-2.77,0.16-4.04,0.84
			c-3.14,1.68-4.59,4.38-4.63,7.83c-0.06,5.64-0.02,11.28-0.02,16.92c0,0-0.01,0-0.01,0c0,5.43,0,10.86,0,16.29
			c0,1.14,0.1,2.26,0.47,3.35c0.98,2.85,3.27,4.26,6.25,3.85c3.37-0.47,6.76-0.87,10.12-1.42c1.73-0.28,3.55-0.15,5.16-1.04
			c3.28-1.81,4.49-4.77,4.49-8.32c0.03-10.95,0.01-21.89,0-32.84C505.77,526.59,505.69,525.82,505.54,525.08z M494.91,532.96
			c-0.01,8.67,0,17.33,0,26c0,1.66-0.14,1.82-1.8,2.12c-2.91,0.52-2.91,0.52-2.91-2.36c0-4.25,0-8.5,0-12.75
			c0-4.63,0.02-9.26-0.01-13.88c-0.01-1.19,0.43-1.7,1.64-1.88C495.14,529.74,494.91,529.78,494.91,532.96z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M446.99,491.91c3.29,0.86,6.51,2,9.77,2.97c1.01,0.3,1.46,0.86,1.43,1.91c-0.04,1.39,0.01,2.78-0.01,4.17
			c-0.04,2.89,0.6,3.17-3.03,3.54c-1.3,0.14-1.59-0.04-1.59-1.34c-0.01-2.15,0-4.29,0-6.44c0-0.49,0.04-0.91-0.72-0.79
			c-3.12,0.47-6.24,0.91-9.36,1.3c-0.75,0.09-0.83,0.46-0.83,1.08c0.02,3.07,0,6.15,0.02,9.22c0.01,1.27,0.26,2.5,0.78,3.67
			c0.88,2,3.2,3.33,5.31,3.04c4.37-0.59,8.73-1.4,13.12-1.74c3.58-0.28,6.64-3.56,7.09-6.95c0.36-2.69,0.05-5.38,0.17-7.26
			c0-3.03,0.06-5.26-0.01-7.49c-0.11-3.52-1.25-4.92-4.6-5.81c-3.13-0.83-6.25-1.65-9.38-2.47c-1.08-0.28-1.63-0.91-1.59-2.07
			c0.04-1.26,0.01-2.52,0.01-3.79c-0.01-3.08-0.41-2.9,3.2-3.42c1.06-0.15,1.47,0.22,1.45,1.28c-0.04,1.94,0.01,3.87-0.02,5.81
			c-0.02,0.78,0.11,1.13,1.04,0.99c2.99-0.47,5.99-0.86,9-1.27c0.7-0.09,0.98-0.36,0.96-1.13c-0.05-2.78-0.03-5.56-0.02-8.33
			c0-1.1-0.15-2.18-0.49-3.22c-0.84-2.61-3.22-4.22-5.78-3.87c-4.5,0.61-8.98,1.35-13.48,1.86c-3.23,0.36-6.21,3.6-6.53,6.75
			c-0.48,4.83-0.22,9.66-0.16,14.5C442.75,489.36,444.16,491.16,446.99,491.91z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M650.51,462.96c-0.59-2.52-2.66-3.13-4.8-3.67c-2.93-0.75-5.84-1.55-8.77-2.29
			c-1.22-0.31-1.77-0.97-1.68-2.25c0.07-1.09,0.01-2.19,0.02-3.28c0.02-3.3-0.47-3.28,3.25-3.78c1.1-0.15,1.48,0.2,1.47,1.24
			c-0.02,2.02,0.01,4.04-0.01,6.06c-0.01,0.61,0.09,0.94,0.83,0.83c3.07-0.48,6.14-0.96,9.22-1.35c0.74-0.1,0.87-0.38,0.86-1.03
			c-0.03-2.99,0.01-5.98-0.03-8.97c-0.02-1.36-0.34-2.66-0.99-3.87c-0.92-1.72-3.24-2.9-5.19-2.63c-4.29,0.59-8.57,1.19-12.85,1.8
			c-4.2,0.59-6.75,2.98-7.23,7.12c-0.54,4.73-0.35,9.5-0.12,14.24c0.16,3.37,1.43,4.47,4.68,5.39c3.19,0.9,6.35,1.91,9.53,2.88
			c0.92,0.28,1.34,0.88,1.3,1.88c-0.05,1.39-0.04,2.78-0.01,4.17c0.07,3.47-0.01,3.2-3.29,3.54c-1.06,0.11-1.45-0.3-1.43-1.34
			c0.04-2.15-0.01-4.29,0.02-6.44c0.01-0.72-0.16-0.94-0.93-0.82c-2.99,0.46-5.99,0.88-8.99,1.24c-0.84,0.1-1.03,0.45-1.01,1.22
			c0.05,1.56,0.01,3.11,0.01,4.67c0.01,0,0.02,0,0.02,0c0,1.6-0.06,3.2,0.01,4.8c0.2,4.28,2.8,7.19,7.55,6.32
			c3.39-0.62,6.83-0.95,10.23-1.49c1.28-0.2,2.62-0.16,3.81-0.74c3.31-1.6,4.78-4.4,4.85-7.94c0.09-4.38,0.03-8.75,0-13.13
			C650.84,464.53,650.69,463.73,650.51,462.96z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M480.52,509.86c2.86-0.44,5.73-0.86,8.61-1.2c0.91-0.11,1.05-0.51,1.05-1.31c-0.03-4.84,0.03-9.68-0.05-14.52
			c-0.02-1.14,0.44-1.43,1.39-1.55c2.34-0.28,4.68-0.56,7.01-0.91c0.78-0.12,1.59-0.1,2.33-0.5c2.85-1.53,4.51-3.86,4.74-7.11
			c0.29-4.08,0.09-8.16,0.11-12.24c0.02-2.19,0.08-4.37-0.1-6.56c-0.26-3.18-2.56-6.26-6.84-5.55c-0.95,0.16-1.9,0.31-2.86,0.44
			c-5.16,0.72-10.32,1.45-15.48,2.12c-0.94,0.12-1.19,0.49-1.19,1.43c0.04,7.7,0.02,15.4,0.02,23.11c0,7.74,0.02,15.49-0.03,23.23
			C479.22,509.85,479.57,510.01,480.52,509.86z M491.31,468.38c0.75,0.01,1.5-0.2,2.24-0.31c0.84-0.13,1.31,0.25,1.32,1.08
			c0.02,3.87,0.02,7.73,0,11.6c0,0.81-0.45,1.32-1.28,1.43c-0.75,0.1-1.53,0.09-2.24,0.32c-1.16,0.37-1.23-0.18-1.21-1.1
			c0.05-1.93,0.01-3.87,0.01-5.8c0-1.93,0.04-3.87-0.02-5.8C490.11,468.91,490.25,468.38,491.31,468.38z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M468.51,524.59c-2.79,0.42-5.57,0.82-8.37,1.18c-0.67,0.09-0.93,0.4-1.03,1.03c-0.21,1.33-0.46,2.65-0.7,3.98
			c-0.68,3.81-1.35,7.61-2.04,11.41c-0.05,0.3,0.1,0.8-0.36,0.82c-0.51,0.02-0.44-0.53-0.52-0.86c-0.11-0.45-0.18-0.91-0.26-1.36
			c-0.81-4.33-1.63-8.67-2.43-13c-0.11-0.62-0.24-0.98-1.03-0.85c-2.82,0.46-5.65,0.9-8.48,1.25c-0.81,0.1-0.93,0.42-0.92,1.12
			c0.04,1.98,0.04,3.96,0,5.93c-0.02,0.91,0.16,1.73,0.5,2.57c1.62,3.97,3.05,8.03,4.85,11.91c2.15,4.64,3.31,9.39,2.78,14.51
			c-0.06,0.62-0.01,1.26-0.01,1.89c0,2.86,0.04,5.73-0.02,8.59c-0.02,0.94,0.19,1.25,1.18,1.1c2.91-0.45,5.83-0.83,8.75-1.19
			c0.81-0.1,0.99-0.48,0.98-1.24c-0.03-5.6-0.01-11.2-0.02-16.8c0-0.81,0.13-1.57,0.41-2.34c2.51-6.91,4.98-13.84,7.48-20.76
			c0.19-0.52,0.34-1.03,0.34-1.59c-0.01-2.1-0.04-4.21,0.01-6.31C469.63,524.7,469.34,524.47,468.51,524.59z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M614.42,443.71c0-1.56,0-1.55-1.5-1.33c-8.02,1.14-16.05,2.29-24.08,3.4c-0.68,0.09-0.94,0.3-0.93,1
			c0.03,2.82,0.03,5.64,0,8.46c-0.01,0.72,0.18,0.96,0.93,0.84c1.82-0.3,3.67-0.48,5.48-0.83c1.06-0.2,1.46-0.09,1.44,1.16
			c-0.07,5.98-0.03,11.95-0.03,17.93c0,6.02,0.03,12.04-0.03,18.05c-0.01,1.13,0.29,1.31,1.36,1.12c2.73-0.49,5.48-0.93,8.24-1.2
			c1.14-0.11,1.34-0.49,1.34-1.53c-0.03-11.99-0.01-23.99-0.05-35.98c0-1.05,0.37-1.36,1.32-1.46c1.8-0.19,3.57-0.54,5.37-0.7
			c0.94-0.09,1.18-0.45,1.15-1.35C614.38,448.76,614.42,446.24,614.42,443.71z"
        />
        <path
          style={{ fill: "#2F2F2F" }}
          d="M431.24,486.84c-3.6,0.58-7.22,1.05-10.83,1.57h0c0,0,0,0,0,0c-3.38,0.46-6.76,0.93-10.13,1.39
			c0,1.71,0,3.42,0,5.13c0,1.71,0,3.42,0,5.13c3.37-0.46,6.74-0.91,10.12-1.36c3.74-0.54,7.48-1.12,11.22-1.59
			c0.89-0.11,1.12-0.44,1.11-1.26c-0.04-2.61-0.07-5.22,0.01-7.82C432.77,486.77,432.32,486.67,431.24,486.84z"
        />
        <path
          style={{ fill: "#2F2F2F" }}
          d="M431.73,478.14c0.76-0.1,0.99-0.39,0.98-1.12c-0.03-2.77-0.03-5.55,0-8.32c0.01-0.85-0.33-1-1.07-0.85
			c-0.9,0.18-1.82,0.29-2.73,0.41c-5.56,0.77-11.13,1.53-16.69,2.33c-1.22,0.17-1.97,1.04-1.98,2.23c-0.01,2.77,0.02,5.54,0.03,8.32
			c3.37-0.48,6.75-0.96,10.12-1.43C424.18,479.18,427.95,478.63,431.73,478.14z"
        />
        <path
          style={{ fill: "#2F2F2F" }}
          d="M410.28,509.47c-0.01,2.77-0.05,5.55-0.03,8.32c0.01,1.27,0.82,1.85,2.1,1.67
			c6.44-0.91,12.87-1.83,19.31-2.71c0.79-0.11,1.07-0.43,1.05-1.22c-0.04-2.48-0.01-4.96-0.01-7.44c0-1.86-0.02-1.82-1.94-1.54
			c-3.44,0.5-6.86,1.15-10.34,1.42C417.09,508.75,413.66,508.91,410.28,509.47L410.28,509.47z"
        />
        <path
          style={{ fill: "#2F2F2F" }}
          d="M417.54,570.43c-0.97,0.11-1.96,0.17-2.92,0.36c-1.43,0.28-2.9,0.39-4.35,0.57
			c-0.01,2.65-0.04,5.29-0.04,7.94c0,1.69,0.55,2.19,2.24,1.96c6.4-0.87,12.79-1.77,19.19-2.63c0.8-0.11,1.06-0.43,1.04-1.22
			c-0.04-1.93-0.01-3.87-0.01-5.8c0-3.48,0.01-3.41-3.44-2.94c-1.47,0.2-2.94,0.45-4.41,0.67c-1.47,0.22-2.95,0.41-4.44,0.49
			C419.49,570.18,418.52,570.33,417.54,570.43z"
        />
        <path
          style={{ fill: "#2F2F2F" }}
          d="M420.39,541.63c3.74-0.53,7.48-1.09,11.22-1.57c0.88-0.11,1.12-0.46,1.11-1.29c-0.04-2.69-0.03-5.39,0-8.08
			c0.01-0.76-0.14-1-0.99-0.88c-6.49,0.93-12.98,1.79-19.47,2.69c-1.25,0.17-2,0.99-2.01,2.2c-0.02,2.78,0.02,5.55,0.03,8.33
			C413.65,542.57,417.02,542.1,420.39,541.63z"
        />
        <path
          style={{ fill: "#2F2F2F" }}
          d="M431.63,559.01c0.86-0.11,1.12-0.47,1.1-1.3c-0.05-2.15-0.02-4.29-0.02-6.44c0-2.77,0.01-2.68-2.78-2.29
			c-3.16,0.45-6.3,1.05-9.5,1.19c-1.69,0.74-3.53,0.58-5.3,0.92c-1.6,0.31-3.24,0.45-4.87,0.67c0,0,0,0,0,0
			c0.01,3.41,0.01,6.81,0.02,10.22c3.37-0.46,6.74-0.93,10.12-1.39C424.14,560.06,427.88,559.5,431.63,559.01z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M414.63,570.79c0.96-0.19,1.94-0.25,2.92-0.36c0.97-0.11,1.94-0.25,2.88-0.6c-0.01-3.08-0.02-6.16-0.02-9.25
			c-3.37,0.46-6.74,0.93-10.12,1.39c-0.11,3.13-0.1,6.26,0,9.38C411.73,571.18,413.2,571.07,414.63,570.79z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M410.28,500.06c-0.1,3.14-0.1,6.27,0,9.41c3.37-0.56,6.81-0.72,10.15-1.5c-0.01-3.09-0.02-6.18-0.03-9.27
			C417.02,499.14,413.65,499.6,410.28,500.06z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M415.13,551.09c1.76-0.34,3.61-0.18,5.3-0.92c-0.01-2.85-0.03-5.69-0.04-8.54c-3.37,0.47-6.74,0.94-10.1,1.41
			v0c-0.01,2.91-0.01,5.81-0.02,8.72C411.89,551.54,413.53,551.4,415.13,551.09z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M420.41,488.41C420.41,488.41,420.41,488.41,420.41,488.41c0-1.46,0-2.92-0.01-4.38c0-1.44,0-2.88-0.01-4.33
			c-3.37,0.48-6.75,0.96-10.12,1.43c-0.05,1.44-0.08,2.89-0.08,4.33c0,1.44,0.02,2.89,0.08,4.33
			C413.66,489.34,417.03,488.87,420.41,488.41z"
        />
      </g>
      <g>
        <path
          style={{ fill: "#FF4D00" }}
          d="M744.47,364.86c-6.32,1.42-10.75,2.18-15.37,2.62c-1.43,0.13-1.57,0.14-3.98,0.22
			c-1.07,0.06-1.07,0.06-1.73,0.09l2.04-10.21c1.38,0.38,2.83,0.51,5.25,0.42c4.69-0.07,8.57-0.72,14.69-2.24L744.47,364.86z
			 M742.51,386.35c-4.37-2.37-7.83-4.71-14.11-9.68l5.97-7.67c2.48,2.9,9.09,8.1,14.36,11.32L742.51,386.35z M767.32,395.45
			c-5.03-1.72-8.52-3.15-13.16-5.5c0.37,6.03,0.4,9.92,0.06,15.32l-9.88-0.72c0.87-6.39,1.11-10.16,1.09-18.58l7.46,1.42l1.85-6.95
			c5.74,3.09,8.28,4.17,11.83,5.23l-9.9-17.3c-0.32,4.23-0.64,6.54-1.68,11.93l-8.91-2.03c1.28-5.19,1.91-8.77,2.31-14.42l6.98,2.25
			l-0.21-0.36c-1.3-2.27-2.35-3.87-3.62-5.61l3.62-2.07l-7.28-0.63c0.96-5.62,1.26-8.33,1.37-13.54l8.51,1.09
			c0.03,4.85-0.1,7.26-0.69,11.98l2.27-1.3c0.64,1.48,1.18,2.55,2.31,4.64l8.21-4.7c1.76-1,3.2-1.96,4.29-2.8l4.64,8.11
			c-1.12,0.43-2.78,1.25-4.64,2.31l-8.16,4.67l1.8,3.15l11.93-6.83c1.91-1.09,3.07-1.83,4.34-2.83l4.76,8.31
			c-1.3,0.47-2.63,1.16-4.64,2.31l-0.77,0.44l2.98,5.22c1.3,2.27,2.26,3.71,3.41,5.24l-7.69,4.4c-0.68-1.67-1.4-3.04-2.88-5.62
			l-2.95-5.16l-4.03,2.31l3.58,6.25c1.42,2.48,2.76,4.59,3.86,6.02l-7.85,4.49c-0.77-1.82-1.73-3.74-3.12-6.17l-0.56-0.98
			L767.32,395.45z M771.86,339.52c-0.08,5.25-0.31,7.85-1.13,13.12l-9.14-1.35c1.08-5.07,1.5-8.4,1.78-13.43L771.86,339.52z"
        />
        <path
          style={{ fill: "#FF4D00" }}
          d="M768.27,453.15c-2.96,0.92-4.42,1.43-6.26,2.31l-2.85-9.21l2.33-0.72l-5.92-19.1l-2.33,0.72l-2.82-9.1
			c2.17-0.42,3.8-0.8,6.53-1.65l13.02-4.03c-1.46-1.48-2.17-2.19-4.03-4.36c3.5-2.58,4.9-3.88,7.07-6.55
			c3.59,4.75,6.54,7.82,10.68,10.96c4.48,3.41,7.87,5.1,13.13,6.59l2.83,9.15c-3.13,3.96-5.1,7.69-6.77,12.94
			c-1.73,5.27-2.43,9.66-2.46,15.4c-3.58-0.89-6.49-1.23-10.01-1.33c0.35-2.91,0.46-3.76,0.92-6.08L768.27,453.15z M763.45,423.92
			l5.97,19.27l7.11-2.2l-5.97-19.27L763.45,423.92z M784.3,438.33c1.78-4.91,3.43-8.04,6.3-12.23c-4.39-1.69-7.83-3.56-12.17-6.7
			L784.3,438.33z M803.93,419.05c-2.87-1.23-4.23-1.99-9.02-5.18c-3.86-2.61-5.98-4.44-10.44-9.17c2.43-2.62,3.88-4.57,5.72-7.69
			c5.47,6.78,11.72,11.7,16.96,13.32L803.93,419.05z M812.92,429.35c-2.13,3.59-3.28,6.31-4.34,10.32
			c-0.84,3.12-1.2,5.79-1.51,10.62c-3.55-1.21-6.25-1.87-9.55-2.28c1.46-9.17,3.4-14.76,8.09-23.56L812.92,429.35z"
        />
        <path
          style={{ fill: "#FF4D00" }}
          d="M779.98,507.51c4.26-4.89,5.82-6.87,9.58-12.08c-5.27-4.58-8.25-8.1-12.75-14.96l2.46,29.89
			c0.31,3.8,0.6,5.86,0.9,7.27l-9.13,0.75c0.1-1.68,0.04-3.88-0.24-7.27l-2.97-36.18l-2.37,0.2l-0.78-9.49
			c2.09,0.07,3.94-0.02,7.26-0.3l40.04-3.29c2.55-0.21,4.44-0.43,6.39-0.76c-0.02,1.97,0.04,3.4,0.3,6.61l3.12,38.02
			c0.32,3.91,0.52,5.63,0.98,7.62l-9.13,0.75c0.07-1.98-0.01-3.7-0.31-7.38l-0.49-5.99l-2.78,7.58c-1.63-0.94-2-1.15-3.37-1.81
			c-3.36-1.64-6.49-3.41-9.59-5.48c-2.91,4.54-5.16,7.65-9.26,13.06L779.98,507.51z M776.76,480c2.31-1.74,3.74-3.17,6.23-6.3
			l-6.7,0.55L776.76,480z M783.76,473.64c2.89,5.38,6.16,9.59,10.95,14.09c2.96-4.66,4.62-7.66,7.3-12.96l6.88,6
			c-3.14,6.05-4.03,7.68-7.01,12.76c3.48,2.34,8.03,4.66,10.77,5.39l-2.26-27.46L783.76,473.64z"
        />
      </g>
      <g>
        <path
          style={{ fill: "#00D441" }}
          d="M265.25,337.18c-1.04-0.8-1.28-0.98-2.17-1.67c1.66,2.63,2.7,4.4,4.12,7.14l28.27,21.71
			c2.03,1.56,3.15,2.35,5.6,3.85l-5.76,7.5c-1.8-1.76-3.09-2.82-5.16-4.42l-12.32-9.46c-0.38-0.29-2.84-2.26-4.74-3.79
			c0.16,0.57,0.26,1.03,0.39,1.35c0.52,1.67,0.59,1.88,0.84,3.5c-3.87-2-6.38-3.03-10.45-4.27c-0.72-4.83-2.54-9.3-5.95-14.62
			c-1.87-2.94-3.35-4.82-5-6.39l6.62-4.52l-0.05-0.04c1.64-1.74,2.85-3.22,5.02-6.05l13.23-17.22c2.9-3.78,3.87-5.14,4.95-6.93
			c1.54,1.41,3.11,2.69,6.32,5.15l29.78,22.87c3.59,2.75,4.94,3.72,6.96,4.9l-5.26,6.84l-1.84-1.41l-12.87,16.76l1.84,1.41
			l-5.22,6.8c-1.51-1.46-3.13-2.86-5.87-4.96L265.25,337.18z M299.32,352.99l12.87-16.75l-27.94-21.46l-12.87,16.75L299.32,352.99z
			 M292.75,328.14c0.76-0.99,1.26-1.73,1.94-2.72c0.63,0.64,0.97,0.89,2.71,2.23l9.15,7.03c1.79,1.38,2.22,1.7,2.86,2.04
			c-0.07,0.09-0.18,0.24-0.22,0.28c-0.14,0.19-0.77,0.91-1.87,2.24l-6.31,8.21c-0.73,0.94-1.22,1.69-1.94,2.72
			c-0.68-0.67-1.06-0.96-2.66-2.19l-9.25-7.1c-1.6-1.23-1.98-1.52-2.81-2.01c0.77-0.9,1.29-1.48,2.13-2.57l0.76-0.99l-2.22-1.7
			l-1.67,2.17c-0.58,0.76-0.97,1.36-1.45,2.19l-6.46-4.96c0.68-0.6,1.13-1.08,1.82-1.98l1.63-2.12l-0.47-0.36
			c-0.85-0.65-1.31-0.93-2.13-1.33l5.04-6.56c0.51,0.61,0.94,1.02,1.84,1.71l0.47,0.36l1.59-2.08c0.73-0.94,1.11-1.55,1.51-2.36
			l6.47,4.96c-0.68,0.68-1.05,1.07-1.74,1.97l-1.7,2.22l2.22,1.7L292.75,328.14z M297.48,340.63l2.25-2.93l-3.4-2.61l-2.25,2.93
			L297.48,340.63z"
        />
        <path
          style={{ fill: "#00D441" }}
          d="M317.09,271.18c0.51,0.98,1.45,2.28,2.82,3.93c4.13,4.93,9.21,9.52,13.14,11.9
			c5.57,3.41,12.32,5.37,19.24,5.56c4.38,0.14,7.54-0.26,12.34-1.48c-0.08,4.26,0.19,6.44,1.38,11.11c-7.53,1.1-13.07,1.08-19-0.09
			c-5.49-1-10.06-2.84-15.67-6.21c3.04,7.62,3.98,12.73,3.51,19.41c-0.36,5.42-1.37,10.15-3.48,15.79
			c-3.98-1.79-5.59-2.23-10.27-2.82c3.93-9.03,4.9-15.58,3.56-22.83c-1.36-7.48-5.59-15.5-12.65-23.94
			c-1.26-1.51-2.07-2.38-3.21-3.37L317.09,271.18z"
        />
      </g>
      <g>
        <path
          style={{ fill: "#FF38E2" }}
          d="M392.9,792.87l12.06,5.4c3.42,1.53,5.8,2.47,7.69,3.06l-3.8,8.47c-1.69-1.02-4.31-2.32-7.4-3.71l-34.81-15.59
			c-3.31-1.49-5.97-2.54-7.86-3.13l3.79-8.47c1.37,0.87,3.38,1.91,6.7,3.39l1.09,0.49l5.62-12.55c1.17-2.61,1.92-4.42,2.62-6.58
			l8.91,3.99c-0.97,1.59-1.93,3.44-3.2,6.26l-5.64,12.6l5.87,2.63l9.54-21.29c1.29-2.88,1.82-4.2,2.5-6.31l8.91,3.99
			c-0.92,1.48-1.68,3.03-2.92,5.8l-2.14,4.78l8.36,3.75c2.71,1.22,4.53,1.96,6.47,2.57l-3.77,8.42c-1.86-1.09-3.57-1.93-6.12-3.07
			l-8.47-3.79L392.9,792.87z M410.25,750.95c1.39,8.25,6.76,17.37,15.02,25.7c-2.95,2.07-4.84,3.83-7.62,6.82
			c-4.02-4.41-7.08-8.65-9.5-12.99c-2.49-4.64-4.13-8.69-5.52-13.88c-4.61,2.43-8.28,3.79-13.98,5.15
			c-5.4,1.29-10.52,1.94-16.03,2.01c0.44-4.17,0.37-6.35-0.16-9.98c10.91,0.39,21.78-2.18,28.98-6.77L410.25,750.95z"
        />
        <path
          style={{ fill: "#FF38E2" }}
          d="M434.33,785.4c-2.49-0.58-3.84-0.83-5.68-1.07l1.54-6.67c1.64,0.56,2.9,0.91,5.57,1.53l5.8,1.34
			c-0.28-1.41-0.42-1.87-0.81-3.12l-5.1-1.18c-2.78-0.64-5.25-1.09-7.26-1.37l1.78-7.71c1.8,0.66,3.28,1.12,6.42,1.85
			c-0.47-1.94-0.89-3.02-2.06-5.36l8.87-0.88c0.98,2.91,1.29,4.2,2.11,8.3l1.68,0.39l0.87-3.77c0.38-1.62,0.58-3.04,0.68-4.24
			l8.7,2.01c-0.34,0.96-0.66,2.05-1.04,3.73l-0.97,4.18l2.38,0.55l0.96-4.17c0.37-1.62,0.56-2.68,0.64-3.82l8.7,2.01
			c-0.4,0.95-0.8,2.38-1.2,4.12l-0.87,3.77l1.74,0.4c2.55-2.59,3.74-4.02,5.44-6.62l7.58,4.74c-1.63,1.76-2.58,2.7-3.97,3.97
			c3.48,0.8,4.78,0.98,6.75,1.19l-1.78,7.71c-1.93-0.63-4.4-1.32-7.18-1.97l-4.81-1.11c-1.04,1.35-1.27,1.54-2.04,2.46l5.45,1.26
			c2.61,0.6,3.96,0.85,5.73,1.08l-1.54,6.67c-1.82-0.6-3.08-0.95-5.63-1.54L457,790.64l-0.41,1.8l12.35,2.85
			c2.43,0.56,3.95,0.85,5.73,1.08l-1.42,6.15c-1.64-0.56-3.19-0.98-5.63-1.54l-12.35-2.85l-0.42,1.8l15.07,3.48
			c3.36,0.78,5.77,1.21,7.5,1.37l-1.69,7.3c-2.38-0.79-4.44-1.39-7.28-2.05l-8.93-2.06c3.26,3.87,8.43,7.14,16.21,10.22
			c-2.95,2.74-4.2,4.16-6.25,7.23c-8.14-4.44-13.43-9.03-17.24-14.98c-0.25,1.59-0.26,1.89-0.59,3.34l-0.43,1.86
			c-0.56,2.44-0.89,4.13-1.02,5.75l-9.22-2.13c0.7-1.73,1.2-3.33,1.73-5.65l0.4-1.74c0.33-1.45,0.37-1.62,0.81-3.23
			c-3.67,2.14-5.65,3.03-9.18,4.05c-3.79,1.08-7.29,1.67-11.82,1.97c-0.57-3.37-1.27-5.6-2.88-8.97c8.02,0.45,13.82-0.35,18.66-2.53
			l-8.12-1.87c-2.9-0.67-5.19-1.08-7.56-1.38l1.69-7.31c1.98,0.7,4.21,1.34,7.34,2.06l14.15,3.27l0.42-1.8l-11.6-2.68
			c-2.44-0.56-4.01-0.87-5.68-1.07l1.42-6.15c1.58,0.55,3.08,0.95,5.57,1.53l11.6,2.68l0.42-1.8L434.33,785.4z M458.43,784.44
			c0.86-1.09,1.13-1.45,1.86-2.5l-10.32-2.38c0.3,1.6,0.37,2.1,0.46,3.04L458.43,784.44z"
        />
      </g>
      <g>
        <defs>
          <path
            id="SVGID_1_"
            d="M307.99,567.18c-0.68-2.55-1.33-5.11-1.92-7.69c-0.15-0.66-0.29-1.32-0.43-1.98
				c-0.57-2.61-1.1-5.23-1.57-7.87c-0.18-1.02-0.34-2.04-0.51-3.07c-0.32-1.93-0.61-3.87-0.87-5.82c-0.23-1.7-0.43-3.4-0.62-5.1
				c-0.15-1.4-0.29-2.81-0.42-4.22c-0.2-2.18-0.36-4.36-0.49-6.55c-0.07-1.17-0.13-2.35-0.18-3.53c-0.06-1.39-0.14-2.78-0.17-4.17
				c-0.03-1.04,0-2.08-0.01-3.12c0-0.59-0.02-1.18-0.02-1.78c0-0.6,0.02-1.19,0.02-1.78c0.01-1.04-0.01-2.08,0.01-3.12
				c0.03-1.39,0.11-2.78,0.17-4.17c0.05-1.18,0.11-2.36,0.18-3.53c0.13-2.18,0.29-4.37,0.49-6.55c0.13-1.41,0.26-2.82,0.42-4.22
				c0.19-1.7,0.39-3.4,0.62-5.1c0.26-1.95,0.55-3.89,0.87-5.82c0.17-1.02,0.33-2.05,0.51-3.07c0.47-2.64,1.01-5.26,1.57-7.87
				c0.14-0.66,0.28-1.32,0.43-1.98c0.68-2.99,1.44-5.96,2.24-8.9c0.08-0.28,0.15-0.56,0.23-0.83c4.39-15.76,10.57-30.77,18.29-44.82
				c0.03-0.06,0.07-0.12,0.1-0.18c1.52-2.76,3.09-5.48,4.73-8.16c0.34-0.56,0.7-1.12,1.05-1.68c1.35-2.17,2.73-4.31,4.15-6.43
				c0.68-1.01,1.38-2,2.07-3c0.55-0.78,1.1-1.56,1.66-2.33c0.24-0.34,0.49-0.67,0.74-1.01c0.14-0.19,0.27-0.38,0.41-0.56
				c1.22-1.66,2.46-3.31,3.73-4.94c0.35-0.45,0.7-0.89,1.05-1.33c0.52-0.66,1.04-1.32,1.57-1.97c1.34-1.65,2.72-3.29,4.11-4.91
				c0.18-0.21,0.37-0.42,0.56-0.63c0.8-0.93,1.61-1.85,2.43-2.76c1.28-1.42,2.59-2.82,3.91-4.2c0.09-0.09,0.18-0.19,0.27-0.28
				c1.1-1.16,2.21-2.31,3.34-3.44c1.13-1.13,2.28-2.23,3.44-3.34c0.09-0.09,0.19-0.18,0.28-0.27c1.39-1.32,2.78-2.63,4.2-3.91
				c0.91-0.82,1.84-1.62,2.76-2.43c0.21-0.18,0.42-0.37,0.63-0.56c1.62-1.4,3.25-2.77,4.91-4.11c0.65-0.53,1.31-1.05,1.97-1.57
				c0.44-0.35,0.89-0.71,1.34-1.06c1.63-1.27,3.27-2.51,4.93-3.72c0.19-0.14,0.38-0.28,0.57-0.41c0.34-0.25,0.67-0.49,1.01-0.74
				c0.77-0.56,1.55-1.11,2.33-1.66c1-0.7,1.99-1.4,3-2.07c2.11-1.43,4.26-2.8,6.43-4.15c0.56-0.35,1.11-0.7,1.68-1.05
				c2.68-1.64,5.4-3.21,8.16-4.73c0.06-0.03,0.12-0.07,0.18-0.1c14.05-7.72,29.06-13.9,44.82-18.29c0.28-0.08,0.56-0.15,0.83-0.23
				c2.94-0.81,5.91-1.56,8.9-2.24c0.66-0.15,1.32-0.29,1.98-0.43c2.61-0.57,5.23-1.1,7.87-1.57c1.02-0.18,2.05-0.34,3.07-0.51
				c1.93-0.31,3.87-0.61,5.82-0.87c1.7-0.23,3.4-0.43,5.1-0.62c1.4-0.15,2.81-0.29,4.22-0.42c2.18-0.2,4.37-0.36,6.55-0.49
				c1.17-0.07,2.35-0.13,3.53-0.18c1.39-0.06,2.78-0.14,4.17-0.17c1.04-0.03,2.08,0,3.12-0.01c0.59,0,1.18-0.02,1.78-0.02
				c0.6,0,1.19,0.02,1.78,0.02c1.04,0.01,2.08-0.01,3.12,0.01c0.58,0.01,1.17,0.03,1.75,0.05l82.74-82.74l-82.57-82.57
				c-2.27-0.04-4.54-0.09-6.82-0.09c-208.11,0-376.81,168.7-376.81,376.81c0,44.43,7.71,87.06,21.84,126.64l28.03-104.61
				L307.99,567.18z"
          />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
        </clipPath>
      </g>
    </g>
  </svg>
)

export default Infographic
